import React from 'react'
import UniqueBanner from '../components/banners/UniqueBanner'
import WhyUnique from '../components/DetailComponents/WhyUnique'

const Unique = () => {
    return (
        <>
            <UniqueBanner />
            <WhyUnique />
        </>
    )
}

export default Unique