import { Link } from 'react-router-dom';
import React from 'react'
import Slider from 'react-slick';
import Cs from '../common/Cs';

function SampleNextArrow(props) {
    const { onClick, className } = props;
    return (
        <div className="ps-carousel">
            <div
                className={className}
                onClick={onClick}
            />
        </div>
    );
}

function SamplePrevArrow(props) {
    const { onClick, className } = props;
    return (
        <div className="ps-carousel">
            <div
                className={className}
                onClick={onClick}
            />
        </div>
    );
}

const ResourcesLibrary = ({ industries, functions }) => {
    const settings = {
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }
    return (
        <div className="container" id='rsr_library'>
            <div className="library">
                <span>OKR Library</span>
                <h2>Learn more about OKR</h2>
                {/* <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. </p> */}
                <h3>Industries</h3>
                {/* <Slider {...settings}>
                    {industries && industries.map(item => (
                        <div className="card" key={item.id}>
                            <div className="image-section">
                                <img src={item.image_path} alt="" />
                                <div className="content">
                                    <Link to={`/detail/-${item.id}`} >
                                        {item.title}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider> */}
                <Cs />
                <h3>Functions</h3>
                {/* <Slider {...settings}>
                    {functions && functions.map(item => (
                        <div className="card" key={item.id}>
                            <div className="image-section">
                                <img src={item.image_path} alt="" />
                                <div className="content">
                                    <Link to={`/detail/-${item.id}`} >
                                        {item.title}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider> */}
                <Cs />
            </div>
        </div>
    )
}

export default ResourcesLibrary