import React from 'react'
import ProductsBanner from '../components/banners/ProductsBanner'
import EmployeeManagement from '../components/products_page/EmployeeManagement'
import PerformanceManagement from '../components/products_page/PerformanceManagement'
import ProductCards1 from '../components/products_page/ProductCards1'
import ProductCards2 from '../components/products_page/ProductCards2'
import ProductManagement from '../components/products_page/ProductManagement'
import ProductOverview from '../components/products_page/ProductOverview'
import ProductsFooter from '../components/products_page/ProductsFooter'

const Products = () => {
    return (
        <>
            <ProductsBanner />
            <ProductOverview />
            <ProductManagement />
            <PerformanceManagement />
            <ProductCards1 />
            <EmployeeManagement />
            {/* <ProductCards2 /> */}
            <ProductsFooter />
        </>
    )
}

export default Products