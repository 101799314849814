import { Link } from 'react-router-dom'
import React from 'react'
import { HashLink } from 'react-router-hash-link'

const Cards = () => {
    return (
        <div className="container">
            <div className="home-cards row">
                <div className="card col-xl-4 col-lg-4 col-md-6 col-sm-12" style={{ backgroundImage: 'url(' + '/home-card-product.png' + ')' }}>
                    <div className="content">
                        <h2>How our Product Works</h2>
                        <p><strong>CALYBER®</strong> allows organizations to work on end-to-end goal management by helping address the complete goal
                            lifecycle and also throws light on crucial areas, which will help address pre-goal-setting preparation
                            and post-goal-setting transformation as well..</p>
                    </div>
                    <HashLink to='/products/#products_banner'>
                        <div className="button-full">
                            KNOW MORE
                        </div>
                    </HashLink>
                </div>
                <div className="card col-xl-4 col-lg-4 col-md-6 col-sm-12" style={{ backgroundImage: 'url(' + '/home-card-unique.png' + ')' }}>
                    <div className="content">
                        <h2>Why CALYBER® is Unique</h2>
                        <p><strong>CALYBER®</strong> works in tandem with our award-winning growth strategy while giving you the complete freedom to build
                            your organization structure and your goal system with our flexible, use-as-you-please model…</p>
                    </div>
                    <HashLink to='/unique/#unique_banner'>
                        <div className="button-full">
                            KNOW MORE
                        </div>
                    </HashLink>
                </div>
            </div>
        </div>
    )
}

export default Cards