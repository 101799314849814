import { Link } from 'react-router-dom'
import React, { useState } from 'react'
import { Modal, Nav, Navbar } from 'react-bootstrap'
import RegisterForm from '../register_form/RegisterForm'

const Header = () => {

    const [show, setShow] = useState(false)
    const [selectedItem, setSelectedItem] = useState({
        image_path:'',
        name: 'Test package',
        short_description: '',
        amount: 100
    })

    return (
        <div className="header" id='header'>
            <Navbar bg="light" expand="lg">
                <Navbar.Brand className='px-5'><Link to="/"><img src="/logo.png" alt="" style={{ width: '70px', height: '70px' }} /></Link></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto">
                        <Nav><Link to="/">Home</Link></Nav>
                        <Nav><Link to="/about">About</Link></Nav>
                        <Nav><Link to="/products">Products</Link></Nav>
                        <Nav><Link to="/pricing">Pricing</Link></Nav>
                        <Nav><Link to="/resources">Resources</Link></Nav>
                        <Nav><Link to="/career">Career</Link></Nav>
                        <Nav><Link to="/contact">Contact</Link></Nav>
                        <Nav onClick={() => setShow(true)}><Link>Register Now</Link></Nav>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <Modal className='modal_stripe' show={show} centered onHide={() => setShow(false)}>
                <div className="p-5">
                    <RegisterForm item={selectedItem && selectedItem} />
                </div>
            </Modal>
        </div>
    )
}

export default Header