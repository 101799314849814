import React from 'react'

const UniqueBanner = () => {
    return (
        <div className="about-banner" id='unique_banner'>
            <div className="content">
                <h2>Our vision is to help you create and empower productive workplaces which are data-driven and reflect a culture of
                    growth and scale on a consistent basis.</h2>
                <p><i>Explore how CALYBER® uses the LEGO building blocks model to help you build and manage your
                    organization and its growth.  The way YOU want it.</i></p>
            </div>
        </div>
    )
}

export default UniqueBanner