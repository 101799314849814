import React from 'react'

const ResourcesBrands = () => {
  return (
    <div className="container">
        <div className="resources-brands">
            <p>Trusted by 100s of Brands Worldwide</p>
            <img src="/partners.png" alt="" />
        </div>
    </div>
  )
}

export default ResourcesBrands