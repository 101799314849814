import React, { useEffect, useState } from 'react'
import { axiosInstance } from '../axios/instance'
import AboutFooter from '../components/about_page/AboutFooter'
import Cs from '../components/common/Cs'
import Features from '../components/pricing_page/Features'
import PricingPlans from '../components/pricing_page/PricingPlans'

const Pricing = () => {
  const [subscriptons, setSubsciptions] = useState()

  const getSubscriptions = () => {
      axiosInstance.get('/subscription/all').then(response => {
          if (!response.data.error) {
              setSubsciptions(response.data.result)
          }
      })
  }

  useEffect(() => {
      getSubscriptions()
  }, [])
  return (
    <>
      <PricingPlans data={subscriptons} />
      {/* <Features data={subscriptons} /> */}
      {/* <Cs /> */}
      <AboutFooter />
    </>
  )
}

export default Pricing