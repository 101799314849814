import { Link } from 'react-router-dom'
import React from 'react'
import Slider from 'react-slick'

const LatestUpdates = ({ data }) => {

    const getDate = (date) => {
        return new Date(date).toString().slice(4, 15)
    }

    function SampleNextArrow(props) {
        const { onClick, className } = props;
        return (
            <div className="ps-carousel">
                <div
                    className={className}
                    onClick={onClick}
                />
            </div>
        );
    }

    function SamplePrevArrow(props) {
        const { onClick, className } = props;
        return (
            <div className="ps-carousel">
                <div
                    className={className}
                    onClick={onClick}
                />
            </div>
        );
    }

    const settings = {
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        dots: false,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    return (
        <div className="container" id='latest'>
            <div className="latest-updates">
                <h2>Latest Updates</h2>
                <p>Stay on top of learning about the latest transformations and discoveries in the field of growth, strategy and management. Calyber brings you news and freshly brewed content to help you learn more.</p>
                <div className="cards">
                    <Slider {...settings}>
                        {data && data.map(item => (
                            <div className="card" key={item.id}>
                                {item.video_key ?
                                    <>
                                        <iframe src={`https://www.youtube.com/embed/${item.video_key}`} frameBorder="0"></iframe>
                                        <p>{item.title}</p>
                                        <span>{getDate(item.date)}</span>
                                    </>
                                    :
                                    <Link to={item.url} target='_blank' >
                                        <img src={item.image_path} alt='card-image' />
                                        <p>{item.title}</p>
                                        <span>{getDate(item.date)}</span>
                                    </Link>
                                }
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    )
}

export default LatestUpdates