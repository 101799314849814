import React from 'react'
import DPAA from '../components/policy_pages/DPAA'

const Dpa = () => {
    return (
        <>
            <DPAA />
        </>
    )
}

export default Dpa