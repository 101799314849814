import { Link } from 'react-router-dom'
import React from 'react'
import { HashLink } from 'react-router-hash-link'

const TermsContent = () => {
  return (
    <div className="container" id='terms'>
      <div className="terms px-1">
        <h2>Terms of Service</h2>
        <p>Updated on April 1, 2023</p>
        <p>
          This master subscription agreement (hereinafter &ldquo;MSA&rdquo;) is an agreement between you or the entity that
          you represent (hereinafter &ldquo;You&rdquo; or &ldquo;Your&rdquo; and Calyber the business management Software as a
          Service (hereinafter &ldquo;Calyber&rdquo; or &ldquo;Service&rdquo;). The MSA governs your usage of Calyber.io from the
          date of signing up for the application. By signing up to Calyber, you agree to the terms of this agreement.
          This Agreement was last updated on April 1, 2023. It is effective between You and Us as of the date of You
          accepting this Agreement.
        </p>
        <h3>Definitions</h3>
        <div style={{ marginLeft: '10px' }}>
          <p><b>We</b>, <b>Us</b>, <b>Our</b>, or <b>Calyber.io</b></p>
          <p className='small'>
            These refer to Calyber Apps Inc, the company who you are contracting with. All legal inquiries should be
            directed to Calyber, Bangalore
          </p>
          <p>
            <b>Software</b> means Calyber.io Software, in Source Code and Object Code, including, as applicable, executable and non-executable
            application files that Customer may download or receive on electronic media.
          </p>
          <p>
            <b>Documentation</b> means any written materials supplied by Calyber.io, either in print or digital format, to be used in conjunction with
            the Software for purposes including, but not limited to, installation, training, and demonstration of the Software.
          </p>
          <p>
            <b>Licensed User</b> means one of Customer&apos;s employees, representatives, consultants, contractors, agents or other persons
            expressly permitted by Customer in connection with Customer&apso;s business affairs who are authorized to use the Software
            and have been supplied user identifications and passwords by Customer (or by Calyber.io at Customer&apos;s request).
          </p>
          <p>
            <b>Third Party</b> means any person or organization who is not an employee, representative, consultant, contractor, agent, or other person
            expressly designated by Customer to conduct business on Customer&apos;s behalf.
          </p>
          <p>
            <b>Derivative Work</b> means any work derived from the Software or in which the Software is an integrated component, and which derivation
            was created or developed, at the expense of, or on the initiative of Customer. Derivative Works may include but are not limited
            to software applications, cloud-based (Software as a Service) applications, or online portals that integrate with the Software.
          </p>
          <p>
            <b>Software Update Release</b> means an embodiment of the Software that delivers minor performance improvements or enhancements of
            existing features and/or functionality to the Software.
          </p>
          <p>
            <b>Software Upgrade Release</b> means an embodiment of the Software that delivers substantial performance improvements, architectural changes
            or new features and/or functionality to the Software, for which Calyber.io may charge a separate license fee.
          </p>
          <p>
            <b>Support Services</b> means the services provided by Calyber.io to troubleshoot installation and usage problems and help resolve them.
          </p>
          <p>
            <b>Maintenance Fee</b> means the mandatory fee charged for update releases, upgrade releases and support services.
          </p>
        </div>

        <br />

        <h3>1. Acceptance of Terms</h3>
        <p>
          If you are entering into this agreement on behalf of a company or other legal entity, you represent that you have the
          authority to bind such entity and its affiliates and users to these terms and conditions, in which case the terms
          &ldquo;you&rdquo; or &ldquo;you&rdquo; shall refer to such entity and its affiliates. If you do not have such authority, or if do not agree
          with the terms and conditions in this agreement, you should not accept this agreement and may not use our services.
          You must meet your country&apos;s legal age requirements to enter into a binding agreement in order to accept the Terms.
        </p>
        <h3>2. Provided Services</h3>
        <p>This section covers the services that are being provided by Calyber.</p>
        <h4>2.1 Provision of the Service</h4>
        <p>
          In order to access the service, you need to sign up for a user account and provide all the information required in the
          signup form. We shall make the Calyber service available to you pursuant to this agreement. You agree that Your acceptance
          hereunder are neither contingent on the delivery of any future functionality or features nor dependent on any oral or
          written public comments made by us regarding future functionality or features.
        </p>
        <h4>2.2 User Subscriptions</h4>
        <p>
          User subscriptions are for designated users and should not be shared or used by more than one user. You can create new users
          by creating new employees in Calyber. Consult the Getting started-guide and other relevant sources to understand more about this.
        </p>
        <p>
          When subscribing to this Service, You agree to: a) provide true and complete information about yourself in the signup form and
          b) maintain and promptly update that information as true and complete. If we have reason to believe that you have provided
          false or incomplete information, we may delete your account and prevent you from using the Services now or in the future.
        </p>
        <h4>2.3 Usage Limitations</h4>
        <p>
          Services may be subject to other limitations, such as, but not limited to, limits on disk space, API load, Page views, etc.
        </p>

        <br />

        <h3>3. Calyber Service Users</h3>
        <p>This section identifies the categories of users and acceptable usage conditions for each category of users:</p>
        <h4>3.1 Categories of Users</h4>
        <p>This section defines the various categories of users:</p>
        <div style={{ marginLeft: '10px' }}>
          <p><b>End users</b> – This category of users use Calyber to manage and run their business. This category has two
            subcategories:</p>
          <p className='small'><b>Entrepreneurs, Business Owners, Promoters</b> – If you own a business or are thinking of
            starting a business, Calyber is the service for you</p>
          <p className='small'><b>Employees</b> – If you are an employee of a business that uses Calyber, you can become a user of
            Calyber for that employer</p>
          <p><b>Competitors</b> – These are competitors of Calyber or those who intend to compete with Calyber</p>
          <p><b>Data Aggregators</b> – These are users who intend to aggregate information from Calyber for any purpose</p>
        </div>
        <h4>3.2 Conditions of Usage for End Users</h4>
        <p>You agree to allow access only to your employees (including yourself) to your account in Calyber.
          You shall not give access to the service to any third party representative. You are not allowed to provide any service based
          on the Service without a prior written permission from us. Using the Services for illegal purposes or spamming is strictly
          prohibited.</p>
        <h4>3.3 Conditions of Usage for Competitors</h4>
        <p>If you are a competitor of Calyber or intend to compete with Calyber, you may not access our services, except with our prior
          written consent.</p>
        <h4>3.4 Conditions of Usage for Data Aggregators</h4>
        <p>If you are a data aggregator, you are not allowed to use Calyber on another user&apos;s behalf, even if that user provides
          their credentials to you. If you need to aggregate data from Calyber, you should become our affiliate and work with us to
          get your service implemented.</p>
        <p>Regarding the protection of your personal information, view Calyber&apos;s <HashLink to='/data_security/#data_security'>Data Security</HashLink>.</p>

        <br />

        <h3>4. Third-Party Providers and Calyber Service Provider Network Services</h3>
        <p>This section covers all the clauses covering services provided/performed by third parties, but are either advertised in or
          integrated into the Calyber service.</p>
        <h4>4.1 Community Ratings</h4>
        <p>In the Calyber community, participants may rate or comment about products, services and service providers. These ratings
          are not reflective of Calyber&apos;s opinions about them and cannot be construed as either our recommendation to use or not
          use a particular product or service or provider.</p>
        <h4>4.2 Signing Up for Third-Party Services and Products</h4>
        <p>At various points in the business process, Calyber might offer third party applications for sale or subscription. Any
          acquisition/use by you of third-party products or services, including but not limited to Third-Party Applications and
          implementation, customization and other consulting services, and any exchange of data between you and any third-party
          provider, is solely between you and the applicable third-party provider. We do not warrant or support third-party products
          or services, whether or not they are designated by us as &ldquo;certified&rdqo; or otherwise, except as specified in the signup form.</p>
        <h4>4.3 Third-Party Applications and Your Data</h4>
        <p>If you subscribe to a third-party service for use with Services, you acknowledge that we may allow providers of those third
          party applications to access your data as needed by both Calyber and the third-party service to interact meaningfully.
          We are not responsible for any disclosure, creation, modification or deletion of your data resulting from any such access
          by third-party providers.</p>
        <h4>4.4 Availability of Third-Party Services</h4>
        <p>Service features that interoperate with third-party services depend on the availability of such services. If a provider
          de-supports or ceases to make an API available to us, Calyber may cease to support that provider.</p>
        <h4>4.5 Performance of Third-Party Services</h4>
        <p>Third-party service integrations may impact the speed at which a particular transaction can be performed in Calyber. We
          are do not take any responsibility of this.</p>

        <br />

        <h3>5. Fees and Payment for Purchased Third-Party Services</h3>
        <p>This section covers all the fees and payments for purchased third-party services.</p>
        <h4>5.1 User Fees</h4>
        <p>The Paid Service Offerings is provided to you at the price and under terms stated in Calyber&apos;s website. Except as
          otherwise specified herein or in Calyber&apos;s website.</p>
        <p className='small'>Fees are quoted and payable in the currency quoted in Calyber.io.</p>
        <p className='small'>Fees are based on services purchased. Additional fees could apply based on actual usage.</p>
        <p className='small'>Payment obligations are non-cancelable and fees paid are non-refundable unless otherwise mentioned in
          section 5.5.</p>
        <p>We reserve the right to change the fees and to start charging for use of Services that are currently available free of charge.
          You will not be charged for using any Services unless you choose to opt for a paid plan.</p>
        <p>User subscription fees are based on monthly periods that begin on the subscription start date and each monthly anniversary
          thereof.</p>
        <h4>5.2 Invoicing and Payment</h4>
        <p>You will provide us with valid and up-to-date credit card information or bank account information. You authorize us to
          charge your credit card or bank account for the initial subscription term and any subsequent renewals. Such charges shall be
          made in advance, either annually or monthly or quarterly, depending on the nature of the fee. For example, if the fee is a
          monthly fee you will be billed monthly. If you cancel your subscription at any point, your account will be active until the
          end of the period that you already paid for. Cancellations will be effective from the next subscription period or until the
          time the current subscription is completed.</p>
        <h4>5.3 Suspension of Service and Acceleration</h4>
        <p>If our attempt to charge your credit card or bank account results in a denial, we may, accelerate your unpaid fee
          obligations under such agreements so that all such obligations become immediately due and payable, and suspend the paid
          services until such amounts are paid in full.</p>
        <h4>5.4 Taxes</h4>
        <p>Unless otherwise stated, our fees do not include any taxes, levies, duties or similar governmental assessments of any nature,
          including but not limited to value-added, sales, use or withholding taxes, assessable by any local, state, provincial,
          federal or foreign jurisdiction. You are responsible for paying all taxes associated with your purchases. If we have the
          legal obligation to pay or collect taxes for which you are responsible, the appropriate amount shall be charged to the same
          credit card or bank account you provide. If you are eligible for any waivers, you have to handle that with the tax authorities
          for appropriate refunds.</p>
        <h4>5.5 Refunds</h4>
        <p>Calyber, Inc will stop monthly or yearly automatic billing on any proxy account or other Calyber account or service provided
          when you opt out of the Paid services. If you sign up for a monthly service and request the service to be cancelled, your
          account will no longer be rebilled starting with the date of your notification. The Paid Plan service will still be active
          until the renewal date; at which time the account will be downgraded to a Plan that is free. We will then stop billing your
          account. Calyber will refund any charges made, if your request was placed before your rebill date and your account was still
          charged.</p>
        <p>Annual plan fees that are discounted are non refundable.</p>

        <br />

        <h3>6. Proprietary Rights</h3>
        <p>This section details your and our proprietary rights.</p>
        <h4>6.1 Reservation of Rights</h4>
        <p>Subject to the limited rights expressly granted herewith, we reserve all rights, title and interest in and to the Services,
          including all related intellectual property rights. No rights are granted to you other than what is expressly set forth
          herein.</p>
        <h4>6.2 Restrictions</h4>
        <p>You shall not:</p>
        <div style={{ marginLeft: '10px' }}>
          <p>Permit anyone except you and your employees to access the services without our permission</p>
          <p>Create derivative works based on the services</p>
          <p>Copy, frame or mirror any part or content of the services, other than for your own internal purposes</p>
          <p>Reverse engineer our software and services</p>
          <p>Access the services in order to:</p>
          <p className='small'>Build a competitive product or service.</p>
          <p className='small'>Copy any features, functions or graphics of the services.</p>
        </div>
        <h4>6.3 User Generated Content</h4>
        <p>You are solely responsible for the photos, profiles (including your name and image), messages, notes, text, information,
          advertisements, listings, and other content that you upload, publish or display (hereinafter, &ldquo;post&rdquo;) on or
          through the Service or the Site, or transmit to or share with other users. You may not post, transmit, or share content on
          the Service that you did not create or that you do not have permission to post.</p>
        <p>You understand and agree that we may, but are not obligated to, review the Service and may delete or remove (without notice)
          any content in our sole discretion, for any reason or no reason, that in our sole judgment violates this Agreement, or which
          might be offensive, illegal, or that might violate the rights, harm, or threaten the safety of users or others. You are solely
          responsible at your sole cost and expense for creating backup copies and replacing any content you transmit to, post, or
          store on the Service.</p>
        <p>As between us and you, you own all rights, title and interest in and to all of your data. Notwithstanding this unconditional
          ownership, if the service is not used by you for more than 180 days consecutively, Calyber may decide to delete your data.
          Unless you permit the use of your content for commercial, marketing or any similar purpose, we will not use, reproduce,
          adapt, modify or publish any content created by you or stored in your Calyber-account. We do have the right to access, copy,
          store, transmit, reformat or distribute the content of your account, if it is required to provide the Services to you.</p>
        <h4>6.4 Feature Requests</h4>
        <p>Calyber encourages users to suggest their ideas for improving the service. We shall have all rights to such requests,
          suggestions, and any content that is part of that request.</p>

        <br />

        <h3>7. Information Protection</h3>
        <p>You are responsible for all activities that occur in your user account. If there is any unauthorized use of your user account,
          you should inform us immediately. You are expected to keep your username, password and other sensitive information
          confidential. We are not responsible for any loss or damage to you or to any third party incurred as a result of any
          unauthorized access and/or use of your user account.</p>
        <p>Regarding the protection of your personal information, view Calyber&apos;s <Link to='/privacy'>Privacy Policy</Link>.</p>

        <br />

        <h3>8. Illegal Activities</h3>
        <p>You are not allowed to use the Service for illegal purposes and/or activities, or to promote illegal activities.</p>
        <p>You are not allowed to use the Service or our website(s) to harass, abuse, harm or discriminate others.</p>
        <p>You will be solely responsible for the activities you use the Services for, and the content you upload or create in the
          Service. We will take no responsibility to monitor the conduct of any User of the Service. Using the service for transmission
          of &ldquo;spam&rdquo;, &ldquo;junk mail&rdquo;, &ldquo;chain letters&rdquo;, &ldquo;phishing&rdquo; or for unsolicited mass distribution of email is forbidden.</p>
        <p>If we have reason to believe that you are using the Services for illegal or unauthorised action, we may eliminate your
          account and prevent you from using the Services now or in the future.</p>

        <br />

        <h3>9. Warranties and Disclaimers</h3>
        <h4>9.1 Our Warranties – We warrant that:</h4>
        <p>Calyber.io warrants that the product/services will conform to the functionality described in then-current Documentation
          when the customer signed up for Calyber. In case of a breach of this warranty, Calyber.io will use commercially reasonable
          efforts to modify the product/service to conform in all material respects to the documentation.</p>
        <p>All the third party services, either software based or not, will be governed by individual agreements between you and the
          third party service providers. Calyber does not make any warranties – express or implied – by creating the integration between
          Calyber and the various service provider network participants</p>
        <p>For any breach of such warranty, your exclusive remedy shall be as provided in Section 14.2 (Termination for Cause) and
          Section 5.5 (Refunds).</p>
        <h4>9.2 Your Warranties</h4>
        <p>You warrant that you will use the service in accordance with the usage policies outlined in Section 2 and the
          policies mentioned throughout this Agreement. You also warrant that you will not use the service to do anything unlawful.
          In cases where you are found to be in conflict the law, Calyber will always take the side of the law and abide by the law,
          including releasing information about your activities, data to the appropriate legal authority. For any breach of such
          warranty:</p>
        <div style={{ marginLeft: '10px' }}>
          <p>Calyber may suspend your account in all the Calyber accounts that you have access to</p>
          <p>Your account may be disallowed to use Calyber.</p>
          <p>You may be disallowed to open new accounts in Calyber</p>
        </div>
        <h4>9.3 Mutual Warranties</h4>
        <p>Each party represents and warrants that:</p>
        <div style={{ marginLeft: '10px' }}>
          <p>It has the legal power to enter into this Agreement</p>
          <p>Will obey the factors and clauses stated in this Agreement</p>
        </div>
        <h4>9.4 Disclaimers</h4>
        <p>We disclaim all warranties of any kind. Except as expressly provided herein, either party makes no warranties of any
          kind, express, implied, statutory or otherwise. This disclaimer applies to everything including warranties of
          merchantability or fitness for a particular purpose, to the maximum extent permitted by applicable law.</p>

        <br />

        <h3>10. Limitation of Liability</h3>
        <p>IN NO EVENT SHALL Calyber BE LIABLE FOR ANY LOST CalyberS, LOSS OF BUSINESS, LOST SAVINGS OR OTHER CONSEQUENTIAL, SPECIAL,
          INCIDENTAL, INDIRECT, EXEMPLARY OR PUNITIVE DAMAGES ARISING OUT OF OR IN CONNECTION WITH ANY BREACH OF THIS AGREEMENT.</p>
        <p>Calyber WILL IN NO EVENT BE LIABLE FOR ANY CONSEQUENTIAL, INCIDENTAL OR INDIRECT DAMAGE, BUSINESS INTERRUPTION, COMPUTER
          FAILURE, OR LOSS OF CalyberS, INFORMATION OR OTHER LOSS ARISING OF OR CAUSED BY YOUR USE OF OR INABILITY TO USE THE SERVICE.
          IN ANY EVENT OUR ENTIRE LIABILITY SHALL BE LIMITED TO AN AMOUNT NOT TO EXCEED, IN THE AGGREGATE FOR ALL CLAIMS, 25% OF THE
          TOTAL ANNUAL DOLLAR AMOUNTS PAID AS FEES TO USE THE SERVICE.</p>
        <h4>10.1 Force Majeure</h4>
        <p>Either Party&apos;s performance will be excused, if and to the extent reasonably necessary, in the event that an act of
          God, war, civil commotion, fire, explosion, or other force majeure event that occurs without the fault or negligence of
          the non-performing Party prevents timely performance under the Agreement.</p>

        <br />

        <h3>11. Indemnification</h3>
        <p>By agreeing to this Agreement, you agree to indemnify and hold harmless Calyber, our employees, suppliers and affiliates
          from and against any losses, damages, fines and expenses, arising out of or relating to any claims that you have used the
          Service in violation of any law, provisions of the terms or any other claim related to your use of Services, except where
          such use is authorized by Calyber.</p>

        <br />

        <h3>12. On Premise Installs</h3>
        <h4>12.1 Customer&apos;s compliance:</h4>
        <p>Subject to Customer&apos;s compliance with the terms and restrictions contained herein, Customer is entitled to use this
          Software for Customer&apos;s own internal business use, but may not sell, transfer, rent, or sub-license the Software,
          Documentation, or Derivative Work to any Third Party in any way. Customer may not grant any Third Party access to any
          administrative functions of the Software including, but not limited to, survey creation and editing, report creation and
          editing, user management functions, and system administrative functions.</p>
        <h4>12.2 Software Usage:</h4>
        <p>Customer may use one copy (instance) of the Software on a single server or single web farm environment for production
          purposes and one copy of the Software on a single server or single web farm environment for testing and/or development
          purposes (Enterprise-level license needed to support web farm environments). Customer may use additional copies of the
          Software on additional servers or web farm environments only if agreed to in writing by Calyber.io and only if all applicable
          license fees have been paid.</p>
        <h4>12.3 License Purchase:</h4>
        <p>Customer has agreed to purchase the number of software licenses as specified through an ordering document. Customer has
          further agreed to pay all fees specified thereon within thirty (30) days from the date of such Ordering Form.</p>
        <h4>12.4 Ownership Copyright:</h4>
        <p>Title to the Software and the Documentation, including Derivative Works thereof, and patents, copyrights and all other
          property rights applicable thereto, shall at all times remain solely and exclusively with Calyber.io, and Customer shall
          not take any action inconsistent with such title. The Software and the Documentation are protected by United States and
          international laws, rules, regulations and treaty provisions. Any rights not expressly granted herein are reserved by
          Calyber.io.</p>
        <h4>12.5 Restrictions of use:</h4>
        <p>Customer may not license, sublicense, rent, resell, lease or otherwise supply the Software, Documentation or Derivative
          Works for use in, or for the benefit of, any Third Party, without the prior written consent of Calyber.io. Customer may
          not modify, enhance, supplement, adapt, translate, reverse engineer, decompile, disassemble or otherwise reduce the
          Software to human readable form. Customer will not use or access the Software to: (i) build a competitive product or
          service, (ii) make or have made a product using similar ideas, features, functions or graphics of the Software, (iii) make
          Derivative Works based upon the Software, except for Customer&apos;s own internal business use (iv) copy any features,
          functions or graphics of the Software. Use, resale or exploitation of the Software except as expressly permitted in this
          Agreement is prohibited.</p>
        <h4>12.6 Maintenance fee:</h4>
        <p>Maintenance fee is a mandatory fee payable annually at the beginning of the term. If the maintenance fee is not paid on
          time, the software license will expire as of the last day of the previous maintenance period.</p>

        <br />

        <h3>13. Confidentiality</h3>
        <p>Customer acknowledges and agrees that the Software and associated Documentation constitute valuable proprietary and
          confidential information and intellectual property (collectively, the &ldquo;Proprietary Information&rdquo;) of Calyber.io and
          its licensors. Customer may not use or disclose Proprietary Information without Calyber.io&apos;s prior written consent,
          except disclosure to and subsequent uses by Customer&apos;s employees and contractors who have a need-to-know, provided such
          employees and contractors have executed written agreements restricting use or disclosure of Proprietary Information that are
          at least as protective as those set forth in this Agreement.</p>
        <p>Customer agrees to use at least the same degree of care in protecting the Proprietary Information as Customer uses to protect
          Customer&apos;s own similar information, but in no event less than reasonable care. Customer acknowledges that due to the
          unique nature of the Proprietary Information, Calyber.io will not have an adequate remedy in money or damages in the event
          of any unauthorized use or disclosure of its Proprietary Information. In addition to any other remedies that may be available
          in law, in equity, or otherwise, Calyber.io shall be entitled to obtain injunctive relief to prevent such unauthorized use or
          disclosure. Customer shall not use any information or data disclosed by Calyber.io in connection with this Agreement to
          contest the validity of any Calyber.io intellectual property. Any such use of Calyber.io information and data shall
          constitute a material, non-curable breach of this Agreement.</p>

        <br />

        <h3>14. Term and Termination</h3>
        <h4>14.1 Term</h4>
        <p>The term of this Agreement will commence on the date you accept it and shall remain in full force and effect for one year
          unless terminated pursuant to the provisions of this Section. Thereafter, this Agreement will be renewed automatically for
          successive one-year periods unless terminated pursuant to the provisions of this Section.</p>
        <h4>14.2 Termination for Cause</h4>
        <p>We reserve the right to terminate your user account and deny the Services upon reasonable belief that you have violated the
          Terms.</p>
        <p>This Agreement and/or any Work Order(s) issued under it may be terminated by either Party by written notice to the other
          Party in the event such other Party materially breaches any of its material obligations as set forth in this Agreement and/or
          any Work Order(s) issued under it and fails to cure such breach within thirty (30) days after written notice thereof from
          such notifying Party. The termination will occur as follows:</p>
        <p className='small'>Calyber will terminate user/account by suspending the user/account. The termination of user account may
          include denial of access to all Services, deletion of information in your user account, and deletion of all data in your user
          account. User/account can terminate Calyber usage by simply not using the service. Calyber reserves the right to delete or
          clean up any unused accounts.</p>
        <h4>14.3 Termination for Convenience.</h4>
        <p>The Client may terminate this Agreement for convenience any time by not using the service. Annual Plan fees that are
          discounted are non refundable.</p>
        <h4>14.4 Survival</h4>
        <p>Provisions of this Agreement which by their express terms or context impose continuing obligations on the Parties will
          survive the expiration or termination of this Agreement for any reason.</p>

        <br />

        <h3>15. General Provisions</h3>
        <p>This section contains all the general provisions of this agreement.</p>
        <h4>15.1 Export Compliance</h4>
        <p>Each party shall comply with the export laws and regulations of the United States and other applicable jurisdictions
          in providing and using the Services. Without limiting the foregoing:</p>
        <div style={{ marginLeft: '10px' }}>
          <p>Each party represents that it is not named on any U.S. government list of persons or entities prohibited from receiving
            exports</p>
          <p>You shall not permit Users to access or use Services in violation of any U.S. export embargo, prohibition or restriction</p>
        </div>
        <h4>15.2 Relationship of the Parties</h4>
        <p>The parties are independent contractors. This Agreement does not create a partnership, franchise, joint venture, agency and
          fiduciary or employment relationship between the parties.</p>
        <h4>15.3 The Agreements</h4>
        <p>This Agreement constitutes the entire agreement between the parties and supersedes all prior and contemporaneous agreements,
          proposals or representations, written or oral, concerning its subject matter. In addition to this agreement other information
          provided by us, such as but not limited to the Privacy Policy, should be taken into consideration. In the event of a conflict
          between this agreement and other information provided by us, The Master Subscription Agreement shall prevail.</p>
        <h4>15.4 Waiver and Cumulative Remedies</h4>
        <p>No failure or delay by either party in exercising any right under this Agreement shall constitute a waiver of that right.</p>
        <h4>15.5 Severability</h4>
        <p>If any provision of this Agreement is held by a court of competent jurisdiction to be contrary to law, the provision shall
          be modified by the court and interpreted so as best to accomplish the objectives of the original provision to the fullest
          extent permitted by law, and the remaining provisions of this Agreement shall remain in effect.</p>
        <h4>15.6 Arbitration and Attorney Fees</h4>
        <p>Any controversy or claim arising out of or relating to the Terms shall be settled by binding arbitration conducted by the
          American Arbitration Association (the “AAA”) under its Commercial Arbitration Rules.</p>
        <p>Any such disputes shall be arbitrated on an individual basis, and shall not be consolidated in any disputes, claims or
          controversies of any other party. The arbitration shall be conducted in California. The arbitrator&apos;s award shall be
          binding and may be entered as a judgment in any court of competent jurisdiction.</p>
        <p>You shall pay on demand all of our reasonable attorney fees and other costs incurred by us to collect any fees or charges
          due us under this agreement following your breach of this agreement.</p>
        <h4>15.7 Communication from Calyber</h4>
        <p>We may communicate with you through emails, newsletters and service announcements. You can unsubscribe from our Newsletter
          but you will not be able to opt-out from receiving service announcements and administrative messages.</p>

        <br />

        <h3>16. Trademark</h3>
        <p>Calyber.io is a trademark of Calyber Apps Inc. We give you permission to use our trademark in your internal communications
          and in your website as long along with the attribution, &ldquol;Calyber.io is a registered trademark of Calyber Apps Inc.&rdquo;</p>
        <p>When you become a customer of Calyber, you consent to have Calyber use your logo in our website, marketing emails with proper
          attribution to your company.</p>

        <br />

        <h3>17. Modification of Terms</h3>
        <p>This Agreement supersedes any previous Agreement to which you and Calyber may have been bound. This Agreement may be modified
          by Calyber in its sole discretion from time to time and such modifications will become part of this Agreement and will be
          effective once posted by Calyber on the Website. You should review the Website and this Agreement from time to time for any
          modifications. Your continued use of the Service will be taken as your agreement to the modified Terms.</p>
      </div>
    </div>
  )
}

export default TermsContent