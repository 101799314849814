import { faArrowDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const FAQ = () => {
    return (
        <div className="conatiner" id='faq'>
            <div className="faq">
                <span>FAQ</span>
                <h2>How can we help you?</h2>
                <input type="text" placeholder='Search for answers' />
                <div className="dropdown">
                    How to add/edit talking points for the Meetings?
                    <div className="arrow">
                        <FontAwesomeIcon icon={faArrowDown} color='gray' />
                    </div>
                </div>
                <div className="dropdown">
                    How to change the account password through the Mobile Application?
                    <div className="arrow">
                        <FontAwesomeIcon icon={faArrowDown} color='gray' />
                    </div>
                </div>
                <div className="dropdown">
                    How to enable and add OKR Dependencies?
                    <div className="arrow">
                        <FontAwesomeIcon icon={faArrowDown} color='gray' />
                    </div>
                </div>
                <div className="dropdown">
                    How to change the status of an Objective?
                    <div className="arrow">
                        <FontAwesomeIcon icon={faArrowDown} color='gray' />
                    </div>
                </div>
                <div className="dropdown">
                    How can I add a new employee to Calyber.io?
                    <div className="arrow">
                        <FontAwesomeIcon icon={faArrowDown} color='gray' />
                    </div>
                </div>
                <div className="button-full">
                    View More
                </div>
            </div>
        </div>
    )
}

export default FAQ