import React from 'react'
import Content from '../components/tricea/Content'

const Tricea = () => {
    return (
        <>
            <Content />
        </>
    )
}

export default Tricea