import { faHandshake } from '@fortawesome/free-regular-svg-icons'
import { faArrowRightFromFile, faBell, faBuilding, faEnvelopeOpenText} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const WhyUnique = () => {
    return (
        <div className="container">
            <div className="why-krama">
                <br />
                <h2>Why CALYBER® is Unique?</h2>
                <p>
                    CALYBER® works in tandem with our award-winning growth strategy while giving you the complete freedom
                    to build your organization structure and your goal system with our flexible, use-as-you-please model.
                </p>
                <ul>
                    <li>Set up your goal system in alignment with your organization&apos;s design</li>
                    <li>Design your strategic goal in alignment with your vision and mission.</li>
                    <li>The entire workflow intuitively encourages you to adopt design thinking principles
                        for setting, disseminating, and managing your goals. </li>
                    <li>Set your organization connect frequencies and expectations while setting your OKRs.</li>
                    <li>Well-designed reports and dashboards</li>
                    <li>Complete OKR History management</li>
                    <li>OKR Archives and Performance Comparisons</li>
                </ul>
                <p>
                    This is the FIRST time such a holistic approach is being taken to effectively implement any organizational
                    management model like OKR, OGSM, GTD, etc.
                </p>
                <p>
                    CALYBER® IS BUILT ON THE WORLD&apos;S FIRST COPYRIGHTED OKR MODEL
                </p>
                <p>
                    CALYBER® uses an award-winning, copyrighted process, another first in the world to our credit, to help you grow and
                    scale in an increasingly competitive marketplace.
                </p>
                <div className="cards row">
                    <div className="card col-xl-2 col-lg-2 col-md-6 col-sm-12">
                        <FontAwesomeIcon icon={faBuilding} size='2xl' color='black' />
                        <h5>BYOOS</h5>
                        <p>Build Your Own Organization Structure with our easy-to-use, DIY model platform for smooth flow of OKRs.</p>
                    </div>
                    <div className="card col-xl-2 col-lg-2 col-md-6 col-sm-12">
                        <FontAwesomeIcon icon={faHandshake} size='2xl' color='black' />
                        <h5>BUSINESS VISIBILITY</h5>
                        <p>Complete forward and backward flow of data across the organization.</p>
                    </div>
                    <div className="card col-xl-2 col-lg-2 col-md-6 col-sm-12">
                        <FontAwesomeIcon icon={faBell} size='2xl' color='black' />
                        <h5>STRUCTURED CHECK-INS</h5>
                        <p>Full history of the cadence across the organization. And check-in frequency management.</p>
                    </div>
                    <div className="card col-xl-2 col-lg-2 col-md-6 col-sm-12">
                        <FontAwesomeIcon icon={faEnvelopeOpenText} size='2xl' color='black' />
                        <h5>PERFORMANCE VISIBILITY</h5>
                        <p>Get a view of collaborations, contributions, deliverables, variance, and much more</p>
                    </div>
                    <div className="card col-xl-2 col-lg-2 col-md-6 col-sm-12">
                        <FontAwesomeIcon icon={faArrowRightFromFile} size='2xl' color='black' />
                        <h5>DATA COMPARISONS</h5>
                        <p>Compare your organization or team&apos;s performance at different time periods for business insights.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhyUnique