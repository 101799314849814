import React from 'react'

const NarrowFooter = () => {
  return (
    <div className="footer">
        <p>2023 © Calyber. All Rights Reserved</p>
    </div>
  )
}

export default NarrowFooter