import { useEffect, useState } from "react";

import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { axiosInstance } from "../../axios/instance";
import Swal from "sweetalert2";

function Payment({ item = null }) {
    const [stripePromise, setStripePromise] = useState(null);
    const [clientSecret, setClientSecret] = useState("");

    const [name, setName] = useState()
    const [address, setAddress] = useState()
    const [pincode, setPincode] = useState()
    const [city, setCity] = useState()
    const [state, setState] = useState()
    const [country, setCountry] = useState()

    const [email, setEmail] = useState()
    const [phone, setPhone] = useState()

    const [show, setShow] = useState(false)

    useEffect(() => {
        axiosInstance.get('/pay/config').then(response => {
            setStripePromise(loadStripe(response.data.publishableKey))
        }).catch(error => {
            console.log(error);
        })
    }, []);

    const handleStripe = () => {
        setShow(true)
        const body = {
            item,
            name,
            address,
            pincode,
            city,
            state,
            country,
            email,
            phone
        }

        if (item && name && address && pincode && city && state && country && email && phone) {
            axiosInstance.post('/pay/create-payment-intent', body).then(response => {
                setClientSecret(response.data.clientSecret)
            }).catch(error => {
                console.log(error);
            })
        } else {
            setShow(false)
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Enter all values',
                showConfirmButton: false,
                timer: 1500
            })
        }
    }

    return (
        <>
            {!show ?
                <>
                    <h3>Enter your Details</h3>
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                            <label>Full Name *</label>
                            <input type="text" placeholder="Enter your name" onChange={e => setName(e.target.value)} />
                            <label>Contact Number *</label>
                            <input type="text" placeholder="Enter your phone number" onChange={e => setPhone(e.target.value)} />
                            <label>Email *</label>
                            <input type="email" placeholder="Enter your email" onChange={e => setEmail(e.target.value)} />
                            <label>Address *</label>
                            <input type="text" placeholder="Enter your address" onChange={e => setAddress(e.target.value)} />
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                            <label>City *</label>
                            <input type="text" placeholder="Enter your city" onChange={e => setCity(e.target.value)} />
                            <label>State *</label>
                            <input type="text" placeholder="Enter your state" onChange={e => setState(e.target.value)} />
                            <label>Country *</label>
                            <input type="text" placeholder="Enter your country" onChange={e => setCountry(e.target.value)} />
                            <label>Pincode *</label>
                            <input type="number" placeholder="Enter your pincode" onChange={e => setPincode(e.target.value)} />
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6">
                            <button className="button-stripe" onClick={handleStripe}>Continue</button>
                        </div>
                    </div>
                </>
                :
                <>
                    <h3>Payment Form</h3>
                    {clientSecret && stripePromise && (
                        <Elements stripe={stripePromise} options={{ clientSecret }}>
                            <CheckoutForm />
                        </Elements>
                    )}
                </>
            }
        </>
    );
}

export default Payment;
