import React from 'react'

const AboutBanner = () => {
    return (
        <div className="about-banner">
            <div className="content">
                <h2>Our vision is to create and empower productive data-driven workspaces that reflect a culture of growth and scalability on a consistent level</h2>
                <p>Explore how CALYBER&apos;s unique features are tailored for your organization</p>
            </div>
        </div>
    )
}

export default AboutBanner