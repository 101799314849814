import React from 'react'
import DataSecurity from '../components/policy_pages/DataSecurity'

const Data_security = () => {
    return (
        <>
            <DataSecurity />
        </>
    )
}

export default Data_security