import React from 'react'

const ActionPlan = () => {
    return (
        <div className="action-plan" id='strategizing' >
            <div className="container">
                <div className="content">
                    <span>SUCCESSFUL STRATEGIZING</span>
                    <h2>Our Unique OKR Implementation Process – From Goals to Growth!</h2>
                    <p style={{ textAlign: 'justify' }}>
                        Our approach is based on 30 years of business experience across domains and industries.  This award-winning
                        approach goes like this.
                    </p>
                    <ol>
                        <li>A thorough assessment of your organization&apos;s constructs, business processes, and performance framework</li>
                        <li>A tailored strategy that includes our award-winning strategic framework, and integration with our software&apos;s tools and features.</li>
                        <li>Expert guidance from our expert team through every step of the process, including coaching, and training.</li>
                        <li>Ongoing support through the learning journey</li>
                    </ol>
                    <p>
                        We prioritize communication and collaboration to ensure that our software is integrated seamlessly into your 
                        operations for a complete transformation for better and measurable business outcomes.
                    </p>
                    <p>
                    Our goal is to provide you with a clear roadmap to success and growth, and we are committed to helping you achieve your goals.
                    </p>
                    <div className="image-container">
                        <div className="cards">
                            <div className="card">
                                <p>Design thinking based Goal Management</p>
                                <span>Experience easy navigation and tracking of objectives and goals.</span>
                            </div>
                            <div className="card">
                                <p>Organization wide Strategic Implementation</p>
                                <span>Track your organizational progress in one place with the backing of real-time data.</span>
                            </div>
                            <div className="card">
                                <p>Organization performance process re-engineering</p>
                                <span>Whether you are a start up of 10 people or 10,000 and more, Calyber has got you covered.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ActionPlan