import React from 'react'

const ContactBanner = () => {
    return (
        <div className="contact-banner">
            <div className="content">
                <h2>Contact Us</h2>
                <p>We would love to hear from you! At Calyber, we are committed to providing exceptional customer service and support. Our user-friendly and knowledge-equipped team is here to answer any questions you may have about our platform.</p>
            </div>
        </div>
    )
}

export default ContactBanner