import React from 'react'

const EmployeeManagement = () => {
    return (
        <div className="container" id='emp_management'>
            <div className="about-us">
                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div className="content">
                            <h2>Employee Engagement</h2>
                            {/* <span>Employee Engagement</span> */}
                            {/* <h2>REAL Employee Engagement is more than Events and Celebrations</h2> */}
                            <p><i>REAL Employee Engagement is more than Events and Celebrations</i></p>
                            <p>Employee engagement is definitely a celebration when done right</p>
                            <p>If you are enabling the employee to:</p>
                            <ul>
                                <li>Control and visualize how he is contributing vs. his deliverables</li>
                                <li>Plan how he wants to grow with the organization. </li>
                            </ul>
                            <p>This kind of growth visibility is what employees look for. </p>
                            <p>CALYBER® helps engage the employees by giving them the platform to track their
                                own performance and growth in alignment with the organization.</p>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div className="collage">
                            <div className="grey-bg">
                                <img src="/employee.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmployeeManagement