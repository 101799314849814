import React from 'react'
import { HashLink } from 'react-router-hash-link'

const AboutFooter = () => {
  return (
    <div className="about-footer">
      <h2>Unlock the potential for growth with the right Goal-setting, Scalability and Progress tracking for your Organization.</h2>
      {/* <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. At quo assumenda laudantium delectus blanditiis, incidunt voluptatum molestias expedita provident iusto tempora ipsa eius quis facilis ad ab aspernatur distinctio molestiae.</p> */}
      <br />
      <HashLink to='/#request_demo'>
        <div className="button-full">Request Demo</div>
      </HashLink>
    </div>
  )
}

export default AboutFooter