import React from 'react'

const ResourcesForm = () => {
    return (
        <div className="container">
            <div className="resources-form">
                <div className="yellow-bg"></div>
                <div className="form">
                    <h2>Request a Demo</h2>
                    <label htmlFor="">company name <span>*</span></label>
                    <input type="text" placeholder='Enter the name of your company' />
                    <label htmlFor="">email <span>*</span></label>
                    <input type="text" placeholder='Enter your email address' />
                    <label htmlFor="">event name <span>*</span></label>
                    <input type="text" placeholder='selct the event' />
                    <div className="row px-3">
                        <div className="button-full">
                            Submit
                        </div>
                        <div className="button-empty">
                            Clear
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResourcesForm