import React from 'react'

const Careers = () => {
    return (
        <div className="container">
            <div className="why-krama">
                <p>Send your detailed resumes to <a href="mailto:contact@calyber.io" target='_blank' rel="noreferrer">contact@calyber.io</a></p>
            </div>
        </div>
    )
}

export default Careers