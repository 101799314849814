import React from 'react'
import SLAA from '../components/policy_pages/SLAA'

const Sla = () => {
    return (
        <>
            <SLAA />
        </>
    )
}

export default Sla