import React from 'react'

const ProductsBanner = () => {
    return (
        <div className="products-banner" id='products_banner'>
            <div className="first-half">
                <div className="content">
                    <h2>Streamline Your Goal-Setting Process and Drive Continuous Improvement with Our Cutting-Edge Software</h2>
                    <p><strong>CALYBER®</strong> allows organizations to work on end-to-end goal management:</p>
                    <ul>
                        <li><strong>Set up your organization – just like you build a Lego Model</strong></li>
                        <li>Set company-level, team-level, and individual-level objectives in alignment with your organization&apos;s model.</li>
                        <li>Break them down into smaller, measurable key results along with aligned, time-bound initiatives. </li>
                        <li>Create and manage collaborations and contributions.</li>
                        <li>Automatically aggregate results from across the organization and see the alignment in ACTION!</li>
                        <li>Set up real-time tracking and powerful MIS at your fingertips for an enhanced decision-making process.</li>
                    </ul>
                    <p>This approach ensures that everyone is aligned and focused on achieving the same objectives, or objectives that
                        MATTER, while still allowing for flexibility in how those objectives are achieved. </p>
                </div>
            </div>
            <div className="second-half">
                <img src="/100.gif" alt="" />
            </div>
        </div>
    )
}

export default ProductsBanner