import React from 'react'

const Review = () => {
  return (
    <div className="home-review">
        <div className="content">
            <p>Lorem ipsum dolor, sit amet qui optio maxime minima voluptatem fugiat id, adipisci itaque omnis soluta quod quasi dicta! Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit, modi ratione! Soluta mollitia maxime illum ab ducimus eum et iusto, aut officia magni asperiores ad ratione quidem, officiis animi expedita!</p>
            <img src="/review-logo.png" alt="" />
            <span><b>Amit Dixit</b>, CEO</span>
        </div>
    </div>
  )
}

export default Review