import { Link } from 'react-router-dom'
import React from 'react'
import { HashLink } from 'react-router-hash-link'

const DataSecurity = () => {
    return (
        <div className="container" id='data_security'>
            <div className="policy px-1">
                <h2>Data Security</h2>

                <br />
                <br />

                <p>
                    <b>Effective and Updated  on April 1, 2023</b>
                </p>
                <h3>Introduction</h3>
                <p>With Calyber.io you can rest assured that we have you covered when it comes to secure management of your
                    business systems. Especially with recent evolutions in security law around the world, it&apos;s paramount that
                    businesses ensure the security of their user and customer data through their own internal practices and those of
                    their vendors. Here at Calyber.io, we understand the trust you place in your business software platform to lead
                    the charge when it comes to ensuring your data remains protected.</p>
                <p>
                    <b>Calyber.io is SSAE18 Certified, Compliant with GDPR Requirements, HIPAA Compliant and ISO 27001:2013 Certified.</b>
                </p>
                <p>This means we take your security seriously, and have done the work to prove it.</p>
                <p>For this document, we&apos;ll dive into the details of each layer in our security and provide most details
                    required by any business owner or IT manager to properly vet Calyber.io as a software vendor. In general,
                    when looking at security Calyber.io breaks apart protections into two categories:</p>
                <p><b>Internal Threats</b> – Having data improperly accessed, exported, or deleted by a user of the system,
                    such as a disgruntled employee deleting all of the business data. Our platform security features explain how your
                    company can design a secure system and mitigate these risks.</p>
                <p><b>External Threats</b> – These are threats related to outside parties like hackers, who may attempt to compromise
                    the system or business data. These threats are mitigated by strong IT policies and systems administration, which
                    Calyber.io takes care of. Below we&apos;ll detail our certifications, network infrastructure, and basic IT security
                    policies designed to protect your data.</p>

                <br />

                <h3>1. Policies & Certifications</h3>
                <p>Calyber.io has a robust system of security & privacy policies verified by both internal resources as well as third
                    parties. These policies are reviewed and updated at least once a year, with the last review being in November 2022.</p>
                <h4>1.1 General Data Privacy Policy</h4>
                <p>Calyber.io follows the principles of EU Safe Harbor and other Privacy domains. We don&apos;t collect any data from
                    users for our use, except during the payment process which is pretty typical for all web-based services. All other
                    information including a user&apos;s location, email and phone number are needed only for those users to run their
                    business on our platform. For example, if they need to print their address, email and phone number on their
                    proposals or invoices. Calyber.io never uses information provided by our users and hence does not mandate users
                    to provide any of that information. All PII and sensitive information are completely optional.</p>
                <p>Calyber.io does not provide any of our client data to third parties. Our policies clearly indicate that clients own
                    their data and we never intend to use their information. We do make an exception to this policy and indicate
                    clearly in our terms of use that we may be forced to share client data with law enforcement in accordance with
                    appropriate legal procedures.</p>
                <h4>1.2 GDPR Compliance</h4>
                <p>Calyber.io is fully compliant with GDPR regulations to be used by companies in Europe (and worldwide) so they can
                    provide that same commitment to their customer privacy. We have formed a team dedicated to managing all data
                    privacy-related inquiries or issues and are also there to support you in your compliance requirements with the
                    guidance you may need for your DPIAs (Data Protection Impact Assessment).</p>
                <p>You can find the complete details of our <HashLink to='/terms/#terms'>terms of use</HashLink> & <HashLink to='/privacy/#privacy'>privacy policy</HashLink> here, which includes all required policies
                    within the GDPR framework. If you have any questions about data privacy please let us know at privacy@Calyber.io.</p>
                <p><HashLink to='/dpa/#dpaa'>Click here</HashLink> to view our Data Processing Agreement (DPA).</p>
                <h4>1.3 SSAE18 Certification</h4>
                <p>Calyber.io utilizes a third party to audit our processes for SSAE18 compliance. The most recent audit was completed
                    in July 2022 and the next review is July 2023. Calyber.io&apos;s most recent certification was for SSAE18 SOC2
                    compliance.</p>
                <h4>1.4 HIPAA Compliance Certification</h4>
                <p>Calyber.io is HIPAA compliance certified and the compliance requirements are reviewed every year. Last review date
                    is September 2022. It is certified that Calyber.io has met all the security controls and requirements to protect
                    Personal Identifiable Information (Pii) and have met all requirements for HIPAA compliance set forth by Health
                    Information Privacy at (HHS.GOV).</p>
                <h4>1.5 ISO 27001:2013 Certified</h4>
                <p>ISO is an independent, non-governmental international standards organization. ISO/IEC 27001 outlines and provides
                    the requirements for an information security management system (ISMS), specifies a set of best practices, and
                    details the security controls that can help manage information risks.</p>
                <p>Calyber.io is certified as ISO/IEC 27001 compliant. Calyber.io ISO/IEC 27001 certificates may be requested through
                    our contact us page. Potential customers can reach out to sales for more information.</p>
                <h4>1.6 Third-Party Vulnerability & Penetration Testing</h4>
                <p>Calyber.io utilizes multiple softwares for malware and vulnerability scans. The malware scans are done on a
                    daily basis and the network vulnerability scans are done on a weekly basis. We also do on-demand network scans
                    as needed.</p>
                <h4>1.7 Data Ownership & Deletion Policy</h4>
                <p>In short, all data is owned by the client and can be deleted on demand at any time. Calyber.io supports a
                    self-delete feature for each account. At this time all business data will be deleted and cleared from rolling
                    backups shortly after. Otherwise, Calyber.io retains basic contact & administrative data, but no client business
                    data. In compliance with GDPR Calyber.io supports the &ldquo;right to be forgotten&rdquo; by emailing privacy@Calyber.io with
                    a request to delete such sales data. A customer is responsible for managing & deleting their own business data
                    if desired.</p>
                <p>For complete details on data ownership please refer to our <HashLink to='/privacy/#privacy'>privacy policy</HashLink></p>

                <br />

                <h3>2. System & Network Infrastructure</h3>
                <p>Calyber.io utilizes 3-tier architecture; web, application and data storage. The systems are secured by Google
                    Cloud VPC Firewall and Google Cloud Armor. The web traffic is managed by Google Cloud Load Balancing. The
                    application and data storage is running on Linux servers. The communication between the layers and to-and-from the
                    internet is encrypted with SSL</p>
                <p>The logical access for systems & database administrators is allowed only to the bastion host, which can be accessed
                    via VPN from the internet. Servers can be accessed only by SSH with username, password and key file from the
                    whitelisted IP&apos;s.</p>
                <p>There are no private connections established for any clients or the service providers. The connection to third-party
                    service providers is established over the internet with SSL encryption.</p>
                <h4>2.1 Hosting Infrastructure</h4>
                <p>Calyber.io&apos;s production environment is hosted on Google Cloud Platform (GCP). Iowa (USA) and Belgium (EU) are
                    the primary data centers. All business data is stored within these primary locations. At the time of signup,
                    customers are free to choose the data center where they want their data to be stored based on the regulations
                    in their country. Data stored within a particular data center resides within that data center and is never moved
                    to another data center owing to strong data security policies of Calyber.io.</p>
                <p>Other than business data, Calyber.io uses Google Cloud CDN and Amazon CloudFront as our content delivery
                    network (CDN). This service distributes all of the support code (javascript / HTML) and image resources required
                    by Calyber.io but does not store or transmit any business data whatsoever. Google Cloud and Amazon CloudFront
                    have servers located all around the world to further decrease loading times.</p>
                <h4>2.2 Disaster Recovery Capabilities</h4>
                <p>Calyber.io has redundant storage in addition to primary data source. Primary data source has replicas to maintain
                    the data store high availability and it (replica) runs within the primary data store. Calyber.io has an incremental
                    backup setup with minimum duration of 15 minutes and maximum duration of 60 minutes. Calyber.io also has a
                    disaster recovery site located at different physical locations (regions) within the GCP.</p>
                <p>See below table for details on disaster recovery locations of different regions</p>
                <p>In the event of a disaster which makes the production environment inaccessible, Calyber.io will initiate the
                    process to bring the DR environment online with the latest data backups. Our most recent recovery process took
                    about 8 hours to complete.</p>
                <h4>2.3 Data Encryption</h4>
                <p>All access to data is provided only through our web, mobile, or API applications. Every connection to the Calyber.io
                    service must be secured by a 256-bit SSL encryption. All data is encrypted in transit, then decrypted to be stored
                    at rest. User passwords used to authenticate with Calyber.io will be salted and hashed and stored in their
                    encrypted form at rest.</p>
                <h4>2.4 Data Segmentation Between Clients</h4>
                <p>All data is carefully segmented between each client. Calyber.io operates multiple multi-tenant platforms within
                    our infrastructure. Standard customers are allocated into a series of sharded databases designed to hold a
                    specified amount of business data. Every record of business data is assigned a unique identifier and tagged to
                    the specific organization it belongs to. For enterprise clients, Calyber.io is able to deploy completely
                    segmented databases which can house one or many tenants from a single client.</p>

                <br />

                <h3>3. Platform Security Features</h3>
                <h4>3.1 User Authentication & SSO</h4>
                <p>The standard Calyber.io authentication requires an email address and password for each user. A user can request
                    a password reset on their own, or an administrator can send a password reset request directly to them.
                    Additionally, an administrator has control to disable the user&apos;s ability to reset their own password and
                    enforce the usage of SSO.</p>
                <h4>3.2 Standard SSO Integrations</h4>
                <p>Calyber.io has multiple out-of-the-box SSO capabilities. We currently integrate with Office 365, Google and Slack.
                    The Microsoft and Google services will function with both the consumer and business class products to provide
                    authentication. This includes the ability to log in from the Calyber.io mobile and web apps, and we also have
                    an installable app for Google/Office 365 which places an SSO link within their Google/Microsoft web interface.</p>
                <p>Office 365</p>
                <ul>
                    <li><p>The data collected from Office 365 will not be shared with third-party applications.</p></li>
                    <li><p>The data in transit between Calyber.io and Office 365 are secure and transferred only after appropriate user
                        authentication.</p></li>
                    <li><p>We do not give governments &ldquo;backdoor&rdqo; access to your data.</p></li>
                </ul>
                <p>Google</p>
                <ul>
                    <li><p>The data from Google are not shared with third-party applications and data are shared between Calyber.io
                        and Google only after appropriate user authentication.</p></li>
                    <li><p>We do not give governments &ldquo;backdoor&rdquo; access to your data</p></li>
                </ul>
                <h4>3.3 Custom SAML Authentication</h4>
                <p>For enterprise clients, Calyber.io has the ability to integrate with any SAML authentication system, such as
                    Microsoft ADFS which can be integrated using SAML 2.0. This custom authentication system is not available in
                    typical Calyber.io deployments and must be requested and implemented individually by the client.</p>
                <h4>3.4 Session Management Controls</h4>
                <p>Our application has a configured session length for each company. The default session timeout is set to 11 days,
                    and then account administrators can change this from their business settings. For companies using SSO, it&apos;s
                    recommended to sync the Calyber.io timeout with your SSO service.</p>

                <br />

                <h3>4. Role Based Access Control</h3>
                <p>A security role is a “profile” that contains a list of specific actions, or privileges that a user can perform.
                    These roles can then be assigned to employees, allowing admins to set up the same level of access for similar
                    employees. One employee can have one or many roles.</p>
                <p>A privilege is a single specific action that can be performed and is specific to one app. For example, one basic
                    privilege is &ldquo;View Contacts&rdquo;. This privilege allows a user to search & view the details of any
                    contact, but not create, update, or perform any other action. There are a basic set of privileges in each
                    app: Access (Add from App Store), View (read-only), Manage (create/update). Additionally, you can create
                    custom privileges in each app, which come into effect when limiting access to features, fields, and special
                    actions. We&apos;ll cover these in detail below.</p>
                <p>System administrators will perform all of the configurations of your roles & privileges in the Employees App
                    settings area, then assign these roles directly to employees in the same app. There are a series of default
                    security roles & privileges available in each app when you sign up, but you can disable/customize these at
                    any time in the settings area.</p>
                <h4>4.1 Data-Level Access</h4>
                <p>Data level access is an additional layer of security over access roles. Enabling this setting will restrict
                    access to the individual object, based on whether all users are assigned to that object, or whether they manage
                    a particular person assigned to that object. For example, an organization might have a sales organization with
                    2 teams of 5 people, each managed by a sales manager, and all led by a sales director.</p>
                <p>This feature enables the sales director to view all records, each sales manager could view their own and their
                    team&apos;s records, and each sales rep could view only their own records. This feature can be turned on or off
                    within each app that supports it individually.</p>
                <h4>4.2 Collaboration Security</h4>
                <p>While data level access controls access to specific records in each of the apps (leads, contacts, cases, etc),
                    collaboration security is the similar concept but applied to the common apps. Collaboration security determines
                    how emails, calendar events, and tasks are shared among employees.</p>
                <p>This allows you to keep these objects private to the user and administrators or can be turned into &ldquo;collaboration
                    mode&rdquo; which will share these records between users.</p>
                <h4>4.3 Report & Action-Level Security</h4>
                <p>Nearly every button, link, report, or action within the system can be restricted to a set of individuals.
                    This is controlled using the same security privileges identified above, but allows businesses to create their
                    own custom privileges, then restrict actions to those users who have such privileges.</p>
                <p>Here are some of the most common action-level security restrictions a business might use:</p>
                <ul>
                    <li><p>Restrict export capabilities</p></li>
                    <li><p>Restrict permanent deletion of data</p></li>
                    <li><p>Restrict access to reports</p></li>
                    <li><p>Restrict creation of records in certain apps</p></li>
                    <li><p>Restrict the ability to perform bulk data changes</p></li>
                </ul>
                <h4>4.4 Field-Level Security</h4>
                <p>While data-level access controls which record a user might be able to view/edit, they still might not have
                    complete access to every field on that record. Calyber.io allows administrators to restrict every field
                    individually using security privileges. An administrator can choose which privilege is required to view a field,
                    edit a field, or submit a value for a field during creation only.</p>
                <h4>4.5 Search Security</h4>
                <p>Action-level security can completely remove the ability for a user to run a search, but some companies might have
                    specific limitations required to control the risk of their data being harvested by a malicious employee. Each
                    field can individually be controlled, and an administrator will choose which types of users are allowed to run
                    a search using that field.</p>

                <br />

                <h3>5. Complete Audit History</h3>
                <p>Calyber.io stores two types of audit logs: System Logs, and News Feed. The system logs are internal logs for
                    details such as when/where an account logged in, and what actions they performed. These logs are only accessible
                    to specially credentialed Calyber.io system administration staff for monitoring and investigation of issues.
                    These system logs are maintained for 30 days before deletion.</p>
                <p>The news feed feature is the complete audit history available to clients. Every change to the data within the
                    system is automatically captured within the news feed. This will capture the user who performed the action,
                    the time, and the old/new values if something was changed. All field changes, the creation of records,
                    updates to records, deletion of records, or any other activity is captured and visible to Calyber.io
                    administrators. This audit history can be filtered down to a specific rep or record within the platform on demand.</p>

                <br />

                <h3>6. Subprocessors</h3>
                <p>To support the delivery of our services, Calyber.io may engage and use data processors (a &ldquo;subprocessor&rdquo;)
                    direct or indirect access to the customer content and personal information that customers may upload,
                    access, process or transmit to our services. This page has important information about the entity,
                    location and role of each subprocessor.</p>
                <p>The subprocessors we currently use provide the infrastructure services including hosting, monitoring,
                    customer support, document storage and email features. We evaluate any third-party subprocessor, we
                    evaluate their privacy and security practices. And their privacy and security policies must be in line
                    with Calyber.io. privacy and security standards.</p>
            </div>
        </div>
    )
}

export default DataSecurity