import React, { useEffect, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form';
import { axiosInstance } from '../../axios/instance';
import { toast, ToastContainer } from 'react-toastify';
import { publicDomainArray } from '../../const/publicDomains';
import { countryCodesArray } from '../../const/countryCodes';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import Loader from '../loader/Loader';

const RegisterForm = ({ item }) => {
    const {
        handleSubmit,
        register,
        formState: { errors },
        reset
    } = useForm();

    const [phone, setPhone] = useState()
    const [countryCode, setCountryCode] = useState('+91')
    const [country, setCountry] = useState()
    const [phoneErr, setPhoneErr] = useState()
    const [emailErr, setEmailErr] = useState()
    const [verify, setVerify] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (phone)
            setPhoneErr()
    }, [phone])

    const notifyErr = (notification) => toast.error(notification, {
        autoClose: 3000
    });

    const submit = (data) => {
        const domain = data.email.split('@')[1]
        if (!phone)
            setPhoneErr('Phone number required')
        else if (phone.length > 13)
            setPhoneErr('Maximum 13 digits are accepted')
        else if (publicDomainArray.includes(domain))
            setEmailErr('Enter a valid work email address')
        // else if (!verify)
        //     notifyErr('Verify you are a human.')
        else {
            data.phone = phone;
            data.subscription_id = 1
            // data.subscription_id = item?.id
            data.current_subscription = 'QUARTZ'
            // data.current_subscription = item?.name
            data.country = country
            data.url = null
            data.country_code = countryCode
            data.no_of_employees = data.institution_type == 'Startup' ? '1-99' : (
                data.institution_type == 'Small Enterprise' ? '100-499' : (
                    data.institution_type == 'Medium Enterprise' ? '500-999' : '1000+'
                )
            )
            setIsLoading(true)
            axios.post('https://calyber.app:3022/iattool_v1.0/0/registration_validation', data)
                .then(response => {
console.log(response)
                    if (response.data.statusCode == 200) {
                        axiosInstance
                            .post(`/stripe/create-checkout-session-subscription`, {
                                // cartItems: [item],
                                cartItems: [{
                                    id: 1,
                                    name: "QUARTZ",
                                    amount: 499,
                                    short_description: "For smaller teams and organizations who are either just getting started with OKRs",
                                    image_path: "https://klitelabs.com/krama/upload/images/subscription/2.png",
                                }],
                                userId: 0,
                                registerData: data
                            })
                            .then((response) => {
                                setIsLoading(false)
                                window.location.href = response.data.url;
                            })
                            .catch((err) => console.log(err.message));
                    } else {
                        setIsLoading(false)
                        notifyErr(response.data.data)
                    }
                }).catch(error => {
                    setIsLoading(false)
                    console.log(error);
                })
        }
    }

    const resetForm = () => {
        reset({
            name: "",
            institution_head: "",
            email: "",
            no_of_employees: "",
            country: "",
            current_subscription: "",
            institution_type: ""
        })
        setPhone()
        setPhoneErr('Phone number requied')
    }

    useEffect(() => {
        if (countryCode) {
            setCountry(countryCodesArray.filter(item => item.dial_code == countryCode)[0].name)
        }
    }, [countryCode])

    const methods = useForm({ defaultValues: {} });

    return (
        <FormProvider {...methods}>
            <div className="container">
                <ToastContainer />
                <form onSubmit={handleSubmit(submit)} className='register-form' >
                    {isLoading && <Loader />}
                    <h5>Tell us about your Company</h5>
                    <div className="row organization_form">
                        <div className="column col-xl-4 col-lg-4 col-md-6 col-sm-12">
                            <label htmlFor="name">Company Name&ensp;<span>*</span></label>
                            <input
                                name='name'
                                type="text"
                                placeholder='Enter the name'
                                {...register("name", { required: "Name is required", minLength: 5, maxLength: 30 })}
                                aria-invalid={errors.name ? "true" : "false"}
                            />
                            <div className="validation">
                                {errors.name?.message && errors.name?.message}
                                {errors.name?.type == 'minLength' && 'Minimum 5 charaters required'}
                                {errors.name?.type == 'maxLength' && 'Maximum 30 charaters required'}
                            </div>
                        </div>
                        <div className="column col-xl-4 col-lg-4 col-md-6 col-sm-12">
                            <label htmlFor="institution_type">Company Type&ensp;<span>*</span></label>
                            <select
                                className='form-select'
                                name='institution_type'
                                placeholder='Select the type'
                                {...register("institution_type", { required: "Organization type required" })}
                            >
                                <option value="Startup">Startup ( 1 - 99 )</option>
                                <option value="Small Enterprise">Small Enterprise ( 100 - 499 )</option>
                                <option value="Medium Enterprise">Medium Enterprise ( 500 - 999 )</option>
                                <option value="Large Enterprise">Large Enterprise ( 1000 + )</option>
                            </select>
                            <div className="validation">
                                {errors.institution_type && errors.institution_type?.message}
                            </div>
                        </div>
                        <div className="column col-xl-4 col-lg-4 col-md-6 col-sm-12">
                            <label htmlFor="email">Work Email Address&ensp;<span>*</span></label>
                            <input
                                name='email'
                                type="email"
                                placeholder='Enter your email address'
                                {...register("email", { required: "Email Address is required", maxLength: 50 })}
                                aria-invalid={errors.mail ? "true" : "false"}
                            />
                            <div className="validation">
                                {errors.email?.message && errors.email?.message}
                                {errors.email?.type == 'maxLength' && 'Maximum 50 charaters required'}
                                {emailErr && emailErr}
                            </div>
                        </div>
                        <div className="column col-xl-4 col-lg-4 col-md-6 col-sm-12">
                            <label htmlFor="institution_head">Contact Person&ensp;<span>*</span></label>
                            <input
                                name='institution_head'
                                type="text"
                                placeholder='Name of the head of the organization'
                                {...register("institution_head", { required: "Head of the organization is required", minLength: 5, maxLength: 30 })}
                                aria-invalid={errors.institution_head ? "true" : "false"}
                            />
                            <div className="validation">
                                {errors.institution_head?.message && errors.institution_head?.message}
                                {errors.institution_head?.type == 'minLength' && 'Minimum 5 charaters required'}
                                {errors.institution_head?.type == 'maxLength' && 'Maximum 30 charaters required'}
                            </div>
                        </div>
                        <div className="column col-xl-4 col-lg-4 col-md-6 col-sm-12">
                            <label htmlFor="phone">Phone Number&ensp;<span>*</span></label>
                            <div className="row w-100 p-0">
                                <div className="col-4 p-0">
                                    <select
                                        className='form-select'
                                        placeholder='Select the Country'
                                        onChange={e => setCountryCode(e.target.value)}
                                    >
                                        {countryCodesArray.map((item, index) => (
                                            <option value={item.dial_code} key={index}>{item.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-8">
                                    <div className="phone_number">
                                        <input
                                            type='number'
                                            placeholder='Enter your number'
                                            onChange={e => setPhone(e.target.value)}
                                        />
                                        <div className="icon">
                                            <p>{countryCode}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="validation">
                                {phoneErr && phoneErr}
                            </div>
                        </div>
                        <div className="column col-xl-4 col-lg-4 col-md-6 col-sm-12">
                            <label htmlFor="country">Country&ensp;<span>*</span></label>
                            <select
                                className='form-select'
                                placeholder='Select the Country'
                                onChange={e => setCountry(e.target.value)}
                            >
                                <option value={country}>{country}</option>
                                {countryCodesArray.map((item, index) => (
                                    <option value={item.name} key={index}>{item.name}</option>
                                ))}
                            </select>
                            <div className="validation"></div>
                        </div>
                        <div className="column col-xl-4 col-lg-4 col-md-6 col-sm-12">
                            <label htmlFor="state">State</label>
                            <input
                                name='state'
                                type="text"
                                placeholder='Enter your state'
                                {...register("state")}
                            />
                            <div className="validation"></div>
                        </div>
                        {/* <div className="column col-xl-4 col-lg-4 col-md-6 col-sm-12">
                            <label htmlFor="current_subscription">Subscription&ensp;<span>*</span></label>
                            <select
                                className='form-select'
                                name='current_subscription'
                                placeholder='Select the subscription'
                                {...register("current_subscription", { required: "Subscription type required" })}
                                onChange={e => setSubscriptionData(e.target.value)}
                            >
                                <option value={null}>Select the subscription</option>
                                {subscriptionList?.map(item => (
                                    <option value={item.name} key={item.id}>{item.name}</option>
                                ))}
                            </select>
                            {errors.current_subscription && <div className='validation' role="alert">{errors.current_subscription?.message}</div>}
                        </div> */}
                    </div>
                    <ReCAPTCHA
                        sitekey='6LeR92QmAAAAAGbgjrwXSeQCjMz0O8jqQoYsZ7OL'
                        onChange={() => setVerify(true)}
                    />
                    <div className="row">
                        <div className=" d-flex justify-center col-xl-4 col-lg-4 col-md-6 col-sm-12">
                            <button className='button-full' >Submit</button>
                            <button className='button-empty ' onClick={resetForm} >Clear</button>
                        </div>
                    </div>
                </form>
            </div>
        </FormProvider>
    )
}

export default RegisterForm
