import React from 'react'

const PrivacyPolicy = () => {
    return (
        <div className="container" id='privacy'>
            <div className="policy px-1">
                <h2>Privacy Policy</h2>

                <p><b>Effective and Updated on April 1, 2023</b></p>
                <h3>Introduction</h3>
                <p>Calyber.io highly respects the privacy of its customers, business partners and employees and recognizes
                    the requirement for appropriate protection and proper management of personal information provided. This
                    privacy policy explains how we collect and use your personal information in relation to Calyber.io websites,
                    services and applications that refer to this privacy policy.</p>

                <br />

                <h3>1. Scope</h3>
                <p>This Privacy Policy applies to all Calyber.io operations, divisions and subsidiaries as far as personal
                    information from the users/ visitors is received in any format whatsoever, including electronic, paper or
                    oral transmission. This Policy also applies to entities which handle and process users/ visitors personal data
                    on behalf of Calyber.io</p>
                <p>This privacy statement describes how Calyber.io collects and uses the personal information you provide on our
                    website: www.Calyber.io and inside the Calyber.io OKR application. It also describes the choices available
                    to you regarding our use of your personal information and how you can access and update this information.
                    If you have questions or concerns regarding this policy, you should first contact us at privacy@Calyber.io</p>
                <h4>1.1 Collection and Use of Personal Information</h4>
                <p>We collect the following personal information from you:</p>
                <ul>
                    <li><p>Contact Information such as, Name, E-mail address, Mailing Address, Phone number</p></li>
                    <li><p>Billing Information such as, Credit Card number and Billing Address.</p></li>
                    <li><p>Unique Identifiers such as User name, Account number, Passwords</p></li>
                    <li><p>Preferences Information such as Marketing preferences, email subscriptions.</p></li>
                    <li><p>Information about your business such as Company name, Company size, Business type.</p></li>
                </ul>
                <p>As is true of most Web sites, we automatically gather information about your computer such as your IP address,
                    browser type, referring/exit pages, and operating system.</p>
                <h4>1.2 We Use This Information to</h4>
                <ul>
                    <li><p>Provision access to Calyber.io application.</p></li>
                    <li><p>Fulfill orders and Send you order confirmation</p></li>
                    <li><p>Assess the needs of your business to determine suitable product requirements.</p></li>
                    <li><p>Send product updates</p></li>
                    <li><p>Respond to customer service requests or queries.</p></li>
                    <li><p>Send you newsletters</p></li>
                    <li><p>Send you marketing communications</p></li>
                    <li><p>Respond to your questions and concerns</p></li>
                    <li><p>Improve our Website and marketing efforts</p></li>
                    <li><p>Conduct research and analysis</p></li>
                    <li><p>Display content based upon your interests</p></li>
                    <li><p>Choice/Opt-Out</p></li>
                </ul>
                <p>You may choose to stop receiving our newsletter or marketing emails by following the unsubscribe instructions
                    included in these emails or you can contact us at privacy@Calyber.io.</p>

                <br />

                <h3>2. Information Sharing</h3>
                <p>We will share your personal information with third parties only in the ways that are described in this
                    Privacy Statement. We do not sell your personal information to third parties.</p>
                <p>We may share your personal information with companies that provide services to help us with our business
                    activities such as payment processing or offering customer service. These companies are authorized to use your
                    personal information only as necessary to provide these services to us.</p>
                <p>We may also disclose your personal information</p>
                <ul>
                    <li><p>As required by law such as to comply with a subpoena, or similar legal process.</p></li>
                    <li><p>When we believe in good faith that disclosure is necessary to protect our rights, protect your safety or
                        the safety of others, investigate fraud, or respond to a government request,</p></li>
                    <li><p>If Calyber.io is involved in a merger, acquisition or sale of all or a portion of its assets, you will be
                        notified via email and/or a prominent notice on our website of any change in ownership or uses of your personal
                        information, as well as any choices you may have regarding your personal information,</p></li>
                    <li><p>To any other third party with your prior consent to do so.</p></li>
                </ul>
                <h4>2.1 Sharing with trusted third parties</h4>
                <p>We may share your personal data with affiliated companies within our corporate family, with third parties with which
                    we have partnered to allow you to integrate their services into our own Services, and with trusted third party
                    service providers as necessary for them to perform services on our behalf.</p>
                <p>We only share your personal data as necessary for any third party to provide the services as requested or as needed
                    on our behalf. These third parties (and any subcontractors) are subject to strict data processing terms and
                    conditions and are prohibited from utilizing, sharing or retaining your personal data for any purpose other
                    than as they have been specifically contracted for (or without your consent).</p>

                <br />

                <h3>3. Communicating with you</h3>
                <p>We may contact you directly or through a third party service provider regarding products or services you have
                    signed up or purchased from us, such as necessary to deliver transactional or service related communications.
                    We may also contact you with offers for additional services we think you&apos;ll find valuable if you give us
                    consent, or where allowed based upon legitimate interests. You don&apos;t need to provide consent as a condition
                    to purchase our services. These contacts may include:</p>
                <ul>
                    <li><p>Email</p></li>
                    <li><p>Newsletters</p></li>
                    <li><p>Text (SMS) messages</p></li>
                    <li><p>Telephone calls</p></li>
                    <li><p>Automated phone calls or text messages.</p></li>
                </ul>
                <p>You may also update your subscription preferences with respect to receiving communications from us and/or our
                    partners by signing into your account and visiting the &ldquo;Account Settings&rdquo; page.</p>
                <p>If we collect information from you in connection with a co-branded offer, it will be clear at the point of
                    collection who is collecting the information and whose privacy policy applies. In addition, it will describe
                    any choice options you have in regards to the use and/or sharing of your personal data with a co-branded partner,
                    as well as how to exercise those options.</p>

                <br />

                <h3>4. Transfer of Personal Data abroad</h3>
                <p>If you utilize our Services from a country other than the country where our servers are located, your
                    communications with us may result in transferring your personal data across International borders. Also,
                    when you call us or initiate a chat, we may provide you with support from one of our global locations outside
                    your country of origin. In these cases, your personal data is handled according to this Privacy policy.</p>

                <br />

                <h3>5. Sharing your billing Information with Third parties</h3>
                <h4>5.1 Stripe</h4>
                <p>When you subscribe to our application, you will provide your credit card number directly to Stripe.
                    Stripe&apos;s privacy policy will apply to the information you provide on the Stripe Website.</p>
                <h4>5.2 Paypal</h4>
                <p>When you subscribe to our application, you will provide your credit card number directly to Paypal.
                    Paypal&apos;s privacy policy will apply to the information you provide on the Stripe Website.</p>
                <h4>5.3 Paytm</h4>
                <p>When you subscribe to our application, you will provide your credit card number directly to Paytm.
                    Paytm&apos; privacy policy will apply to the information you provide on the Stripe Website.</p>

                <br />

                <h3>6. Cookies and Other Tracking Technologies</h3>
                <p>We may use cookies, for example, to keep track of your preferences and profile information and assist you better.
                    Cookies are also used to collect general usage and volume statistical information that does not include personal
                    information.</p>

                <br />

                <h3>7. Web Beacons</h3>
                <p>Our Web pages contain electronic images known as Web beacons (sometimes called single-pixel gifs) and are used
                    along with cookies to compile aggregated statistics to analyze how our site is used and may be used in some of
                    our emails to let us know which emails and links have been opened by recipients.</p>
                <p>This allows us to gauge the effectiveness of our customer communications and marketing campaigns.</p>
                <p>We use a third party to gather information about how you and others use our Website. For example, we will know how
                    many users access a specific page and which links they clicked on. We use this aggregated information to
                    understand and optimize how our application is used.</p>

                <br />

                <h3>8. Links to Other Websites</h3>
                <p>Our Site includes links to other Websites whose privacy practices may differ from those of Calyber.io. If you
                    submit personal information to any of those sites, your information is governed by their privacy statements.
                    We encourage you to carefully read the privacy statement of any Website you visit.</p>

                <br />

                <h3>9. Security</h3>
                <p>The security of your personal information is very important to us. We follow globally accepted industry standards
                    to protect the personal information submitted to us, both during transmission and once we receive it. No method
                    of transmission over the Internet or method of electronic storage is 100% secure, however. Therefore, we cannot
                    guarantee its absolute security. If you have any questions about security on our Website, you can contact us at
                    privacy@Calyber.io.</p>
                <p>We never transfer the users&apos; data. We always comply with applicable laws. Moreover, the data is stored in an
                    encrypted form. Using the users&apos; data for advertising will not be permitted at any means. We also prohibit
                    accessing the users&apos; data by the employees except for resolving technical issues, aggregating data to be
                    made available to the users, and for security purposes. We abide by the rules of GDPR and other applicable laws.</p>
                <p>We will retain your information as long as your account is active or as needed to provide you services, unless
                    otherwise specified by you.</p>
                <p>If you wish to cancel your account or request that we no longer use your information to provide you services,
                    ontact us at privacy@Calyber.io. We will retain and use your information as necessary to comply with our legal
                    obligations, resolve disputes, and enforce our agreements.</p>

                <br />

                <h3>10. Compliance with legal, regulatory and law enforcement requests.</h3>
                <p>We cooperate with government and law enforcement officials and private parties to enforce and comply with the law.
                    We will disclose any information about you to government or law enforcement officials or private parties as we,
                    in our sole discretion, believe necessary or appropriate to respond to claims and legal process (such as
                    subpoena requests), to protect our property and rights or the property and rights of a third party, to
                    protect the safety of the public or any person, or to prevent or stop activity we consider to be illegal
                    or unethical</p>
                <p>To the extent we are legally permitted to do so, we will take reasonable steps to notify you in the event that
                    we are required to provide your personal information to third parties as part of the legal process.</p>


                <br />

                <h3>11. Website Analytics</h3>
                <p>We use multiple web analytics tools provided by service partners such as Google Analytics, hotjar, Facebook
                    Audiences, Facebook Connect, G2Crowd, Newrelic, Hubspot, Zoominfo and PureChat to collect information about
                    how you interact with our website or mobile applications, including what pages you visit, what site you
                    visited prior to visiting our website, how much time you spend on each page, what operating system and
                    web browser you use and network and IP information, and have live chat capability with our visitors.</p>
                <p>We use the information provided by these tools to improve our Services. These tools place persistent
                    cookies in your browser to identify you as a unique user the next time you visit our website. Each cookie
                    cannot be used by anyone other than the service provider (ex: Google for Google Analytics). The information
                    collected from the cookie may be transmitted to and stored by these service partners on servers in a country
                    other than the country in which you reside.</p>
                <p>Though information collected does not include personal data such as name, address, billing information, etc.,
                    the information collected is used and shared by these service providers in accordance with their individual
                    privacy policies. You can control the technologies we use by managing your settings through our Cookie Policy
                    or the &ldquo;cookie banners&rdquo; that may be presented (depending on URL of the website visited) when
                    you first visit our web pages, or by utilizing settings in your browser or third-party tools,such as
                    disconnect, Ghostery, and others.</p>

                <br />

                <h3>12. Additional Policy Information</h3>
                <p>Our website offers publicly accessible blogs or community forums. You should be aware that any information
                    you provide in these areas may be read, collected, and used by others who access them.</p>

                <br />

                <h3>13. Correcting and Updating Your Personal Information</h3>
                <p>To review and update your personal information to ensure it is accurate, contact us at privacy@Calyber.io.</p>

                <br />

                <h3>14. Age Restrictions</h3>
                <p>Our Services are available for purchase only for those over the age of 18. Our Services are not targeted to,
                    intended to be consumed by or designed to entice individuals under the age of 18. If you know of or have
                    reason to believe anyone under the age of 18 has provided us with any personal data, please contact us at
                    privacy@Calyber.io.</p>

                <br />

                <h3>15. Notification of Privacy Statement Changes</h3>
                <p>We may update this privacy statement to reflect changes to our information practices at any time. If we make
                    any material changes we will notify you by email (sent to the e-mail address specified in your account) or
                    by means of a notice on this Site prior to the change becoming effective. We encourage you to periodically
                    review this page for the latest information on our privacy practices.</p>

                <br />

                <h3>16. Compliance process with respect to International Data Privacy</h3>
                <p>Calyber.io follows the principles of EU Safe Harbor and other Privacy domains. We don&apos;t collect any data
                    from users for our use, except during the payment process which is pretty typical for all web based services.
                    All other information including a user&apos;s location, email and phone number are needed only for those users
                    to run their business on our platform. Calyber.io never uses information provided by our users and hence does
                    not mandate users to provide any of that information. All PII and sensitive information are completely optional
                    and used only to provision access to applications. Calyber.io does not provide any of our client data to third
                    parties. Our policies clearly indicate that clients own their data and we never intend to use their
                    information. We do make an exception to this policy and indicate clearly in our terms of use that we may
                    be forced to share client data with law enforcement in accordance with appropriate legal procedures.</p>

                <br />

                <h3>17. Privacy Principles</h3>
                <p>Calyber.io commits to subject the Personal information covered by this policy to the following principles:</p>
                <p><b>(1) NOTICE</b>: Where Calyber.io collects Personal information directly from individuals, it will inform them
                    about the purposes for which it collects, stores and processes Personal information about them, and the choices and
                    means, if any, Calyber.io offers individuals for limiting the use and disclosure of their Personal information.
                    Notice will be provided in clear and conspicuous language when individuals are first asked to provide Personal
                    information to Calyber.io, or as soon as practicable thereafter, and in any event before Calyber.io uses the
                    information for a purpose other than that for which it was originally collected.</p>
                <p><b>(2) CHOICE</b>: Calyber.io will offer individuals the opportunity to choose (opt-out) whether their Personal
                    information is to be used for a purpose other than the purpose for which it was originally collected or subsequently
                    authorized by the individual. Calyber.io will provide individuals with reasonable mechanisms to exercise their choices
                    should requisite circumstances arise.</p>
                <p><b>(3) DATA INTEGRITY AND PURPOSE LIMITATION</b>: Calyber.io will use Personal information only in ways that are
                    compatible with the purposes for which it was collected or subsequently authorized by the individual. Calyber.io
                    will take reasonable steps to ensure that Personal information is relevant to its intended use, accurate, complete
                    and current.</p>
                <p><b>(4) ACCESS AND CORRECTION</b>: Upon request, Calyber.io will grant individuals reasonable access to Personal
                    information that it holds about them. In addition, Calyber.io will take reasonable steps to permit individuals to
                    correct, amend or delete information that is demonstrated to be inaccurate or incomplete. Any employees that
                    desire to review or update their Personal information can do so by contacting their local Human Resources
                    Representative.</p>
                <p><b>(5) SECURITY</b>: Calyber.io will take reasonable precautions to protect Personal information in its
                    possession from loss, misuse and unauthorized access, disclosure, alteration and destruction. Calyber.io protects
                    data in many ways. Physical security is designed to prevent unauthorized access to database equipment and hard
                    copies of sensitive Personal information. Electronic security measures continuously monitor access to our servers
                    and provide protection from hacking or other unauthorized access from remote locations. This protection includes
                    the use of firewalls, restricted access and encryption technology. Calyber.io limits access to Personal
                    information and data to those persons in Calyber.io&apos;s organization,that have a specific business purpose
                    for maintaining and processing such Personal information and data. Individuals who have been granted access to
                    Personal information are aware of their responsibilities to protect the security, confidentiality and integrity
                    of that information and have been provided training and instruction on how to do so.</p>
                <p><b>(6) ACCOUNTABILITY FOR ONWARD TRANSFER</b>: Calyber.io acts as a data controller of Personal Data collected
                    from EU residents and may transfer data onward for processing to other countries, which may not possess Data
                    Protection adequacy levels.</p>
                <p>a. To transfer personal information, Calyber.io complies with the Notice and Choice Principles of Standard
                    Contractual Clauses. Calyber.io declares that such data is only processed for limited and specified purposes
                    consistent with the consent provided by the individual.</p>
                <p>b. To transfer personal data outside of EU, Calyber.io:</p>
                <p className='small'>(i) Confirms that the transfer of such data is only for limited and specified purposes;</p>
                <p className='small'>(ii) is obligated to provide at least the same level of privacy protection as is required by
                    the Principles;</p>
                <p className='small'>(iii) will take reasonable and appropriate steps to ensure that it effectively processes the
                    personal information transferred in a manner consistent with the its obligations under the Principles;</p>
                <p className='small'>(iv) requires to determine that it can no longer meet its obligation to provide the same level
                    of protection as is required by the Principles;</p>
                <p className='small'>(v) upon notice, will undertake reasonable and appropriate steps to stop and remediate
                    unauthorized processing; and</p>
                <p className='small'>(vi) will provide a summary or a representative copy of the relevant privacy provisions
                    to the Department upon request.</p>
                <p><b>(7) RECOURSE, ENFORCEMENT, and LIABILITY</b>: In compliance with the Standard Contractual Clauses,
                    Calyber.io commits to resolve complaints about your privacy and our collection or use of your Personal Data.
                    Data Subjects with inquiries or complaints regarding this Privacy Policy should first contact
                    Calyber.io at: privacy@Calyber.io</p>
                <p>Calyber.io agrees to periodically review and verify its compliance with the Standard Contractual Clauses,
                    and to remedy any issues arising out of failure to comply with the Standard Contractual Clauses. We commit
                    to investigate and attempt to remedy all valid complaints.</p>
                <p><b>A. Employee Recourse</b>: Employees may file a complaint concerning Calyber.io&apos;s processing of their
                    Personal Data. Calyber.io will take steps to remedy issues arising out of its alleged failure to comply with
                    the Standard Contractual Clauses. Employees may contact Calyber.io about complaints regarding Calyber.io&apos;s
                    Personal Data practices.</p>
                <p>If an Employee&apos;s complaint cannot be resolved through Calyber.io&apos;s internal processes, Calyber.io
                    will cooperate with the panel of EU data protection authorities established pursuant to the Standard
                    Contractual Clauses to address relevant Employee complaints and provide Employees with appropriate recourse
                    free of charge.</p>
                <p><b>B. Consumer Recourse</b>: Consumer Recourse: Consumers may file a complaint concerning Calyber.io&apos;s
                    processing of their Personal Data. Calyber.io will take steps to remedy issues arising out of its alleged
                    failure to comply with the Standard Contractual Clauses. Consumers may contact Calyber.io about complaints
                    regarding Calyber.io&apos;s Consumer Personal Data practices.</p>
                <p>Calyber.io also is subject to the investigatory and enforcement powers of the U.S. Federal Trade Commission.
                    It provides, at no cost to the individual, an independent recourse mechanism by which each individual&apos;s
                    complaints and disputes can be investigated and expeditiously resolved.</p>
                <p>Calyber.io also has the Data Processing Agreement(&ldquo;DPA&rdquo;) which forms part of the End User License
                    and Services Agreement (the &ldquo;Agreement&rdquo;) between Customer and Calyber.io, to reflect the
                    parties&apos; agreement about the Processing of Personal Data, when applicable, in accordance with the
                    requirements of Data Protection Laws and Regulations.</p>
                <p>If you utilize our Services from a country other than the country where our servers are located, your
                    communications with us may result in transferring your personal data across international borders.
                    Also, when you call us or initiate a chat, we may provide you with support from one of our global
                    locations outside your country of origin. In these cases, your personal data is handled according
                    to this Privacy Policy. Calyber.io will take reasonable steps to prevent or stop the use or disclosure.
                    Calyber.io shares your personal data with affiliated companies within our corporate family,
                    with third parties with which we have partnered to allow you to integrate their services into
                    our own Services, and with trusted third party service providers as necessary for them to perform
                    services on our behalf. Calyber.io will confirm that any third party to which it discloses Personal
                    information will appropriately safeguard the privacy of that Personal information.</p>
                <p>Examples of appropriate privacy safeguards include: a contract obligating the third party to provide at
                    least the same level of protection as is required by the relevant privacy principles, the third party
                    being subject to EU data protection law,Standard Contractual Clauses by the third party, or the third
                    party being subject to another European Commission adequacy finding. When Calyber.io has knowledge that
                    a third party is using or disclosing Personal information in a manner contrary to this Policy,
                    Calyber.io will take reasonable steps to prevent or stop the use or disclosure. Calyber.io may
                    remain liable under the Standard Contractual Clauses if any third parties processes Personal
                    information in a manner inconsistent with the Standard Contractual Clauses, unless Calyber.io
                    first demonstrates that it is not responsible for the event giving rise to the damage</p>

                <br />

                <h3>18. Dispute Resolution</h3>
                <p>Any questions or concerns regarding the use or disclosure of Personal information should be directed
                    to the Calyber.io Data Protection and Privacy Office. Calyber .co will investigate and attempt to
                    resolve complaints and disputes regarding use and disclosure of Personal information in accordance
                    with the principles contained in this Policy. For complaints that cannot be resolved between
                    Calyber.io and the complainant, Calyber.io has agreed to participate in the dispute resolution
                    procedures of the panel established by the U.S Federal Trade Commission (or) the EU data protection
                    authorities (based on the data subject region) and to cooperate and comply with the Information
                    Commissioner to resolve disputes pursuant to the relevant privacy principles. In some circumstances,
                    complainants may be able to appeal these decisions by invoking binding arbitration. All of
                    these dispute resolution mechanisms are free of charge to you.</p>















            </div>
        </div>
    )
}

export default PrivacyPolicy