import { faLinkedin, faTwitter, faWhatsapp, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faGlobe, faMapLocation, faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import Maps from './Maps'

const ContactTouch = () => {
  return (
    <div className="touch">
      <div className="container">
        <div className="footer">
          <div className="info">
            <div className="over-head">
              <span>contact us</span>
              <h2>Get in touch</h2>
            </div>
            <p>We&apos;ll get back to you ASAP.</p>
            <div className="link">
              <div className="icon">
                <FontAwesomeIcon icon={faGlobe} color='red' />
              </div>
              <p>https://okrleague.com</p>
            </div>
            <div className="link">
              <div className="icon">
                <FontAwesomeIcon icon={faPhone} color='yellow' />
              </div>
              <p>+91 99999 88888</p>
            </div>
            <div className="link">
              <div className="icon">
                <FontAwesomeIcon icon={faEnvelope} color='orange' />
              </div>
              <p>info@calyber.io</p>
            </div>
            <div className="link">
              <div className="icon">
                <FontAwesomeIcon icon={faMapLocation} color='red' />
              </div>
              <p>Bangalore - 560102</p>
            </div>
            <div className="icons">
              <div className="icon">
                <FontAwesomeIcon icon={faLinkedin} color='yellow' />
              </div>
              <div className="icon">
                <FontAwesomeIcon icon={faYoutube} color='red' />
              </div>
              <div className="icon">
                <FontAwesomeIcon icon={faWhatsapp} color='orange' />
              </div>
              <div className="icon">
                <FontAwesomeIcon icon={faTwitter} color='yellow' />
              </div>
            </div>
          </div>
        </div>
        <div className="map">
          <Maps />
        </div>
      </div>
    </div>
  )
}

export default ContactTouch