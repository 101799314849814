import React from 'react'
import PrivacyPolicy from '../components/policy_pages/PrivacyPolicy'

const Privacy = () => {
    return (
        <>
            <PrivacyPolicy />
        </>
    )
}

export default Privacy