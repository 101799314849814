import { Link } from 'react-router-dom'
import React from 'react'

const ProductsFooter = () => {
  return (
    <div className="products-footer">
      <h2>Seamlessly integrate with other favourite apps and platforms</h2>
      <Link to='/#request_demo'>
        <div className="button-full">Request a Demo</div>
      </Link>
    </div>
  )
}

export default ProductsFooter