import React, { useState } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-number-input";

const Paypal = ({ props }) => {
    const [show, setShow] = useState(false);
    const [success, setSuccess] = useState(false);
    const [ErrorMessage, setErrorMessage] = useState("");
    const [orderID, setOrderID] = useState(false);

    const [firstName, setFirstName] = useState()
    const [firstNameErr, setFirstNameErr] = useState()
    const [phone, setPhone] = useState()
    const [phoneErr, setPhoneErr] = useState()
    const [email, setEmail] = useState()
    const [emailErr, setEmailErr] = useState()

    const notify = (notification) => toast(notification, {
        autoClose: 1000
    });

    const createOrder = (data, actions) => {
        return actions.order
            .create({
                purchase_units: [
                    {
                        description: "Calyber",
                        amount: {
                            currency_code: "USD",
                            value: 20,
                        },
                    },
                ],
                // not needed if a shipping address is actually needed
                application_context: {
                    shipping_preference: "NO_SHIPPING",
                },
            })
            .then((orderID) => {
                setOrderID(orderID);
                return orderID;
            });
    };

    const onApprove = (data, actions) => {
        return actions.order.capture().then(function (details) {
            const { payer } = details;
            setSuccess(true);
            notify('Payment Successful')
        });
    };

    const onError = (data, actions) => {
        setErrorMessage("An Error occured with your payment ");
        notify('Something went wrong')
    };

    return (
        <div className="paypal">
            <PayPalScriptProvider
                options={{
                    "client-id": "test",
                }}
            >
                <div className="close" onClick={props}>
                    <p>X</p>
                </div>
                <div className="payment-form">
                    <div className="yellow-bg"></div>
                    <div className="form">
                        <div className="row">
                            <div className="column">
                                <label htmlFor="">Name <span>*</span></label>
                                <input type="text" placeholder='Enter your first name' value={firstName} onChange={e => setFirstName(e.target.value)} />
                                <label htmlFor=""><span>{firstNameErr && firstNameErr}</span></label>
                                <label htmlFor="">Email address <span>*</span></label>
                                <input type="text" placeholder='Enter your email address' value={email} onChange={e => setEmail(e.target.value)} />
                                <label htmlFor=""><span>{emailErr && emailErr}</span></label>
                                <label htmlFor="">Phone number <span>*</span></label>
                                <PhoneInput
                                    placeholder="Enter phone number"
                                    value={phone}
                                    onChange={setPhone}
                                    style={{ width: '90%' }}
                                />
                                <label htmlFor=""><span>{phoneErr && phoneErr}</span></label>
                            </div>
                        </div>
                    </div>
                </div>
                <PayPalButtons
                    style={{ layout: "vertical" }}
                    createOrder={createOrder}
                    onApprove={onApprove}
                />
            </PayPalScriptProvider >
        </div >
    )
}

export default Paypal