import './App.css';
import '../src/styles/style.scss'

import 'bootstrap/dist/css/bootstrap.css'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import 'react-toastify/dist/ReactToastify.css'
import 'react-phone-number-input/style.css'

import { Route, Routes } from 'react-router-dom';
import Home from './pages';
import About from './pages/about';
import Career from './pages/career';
import Contact from './pages/contact';
import Data_security from './pages/data_security';
import Dpa from './pages/dpa';
import Grow from './pages/grow';
import Pricing from './pages/pricing';
import Privacy from './pages/privacy';
import Products from './pages/products';
import Unique from './pages/unique';
import Tricea from './pages/tricea';
import Terms from './pages/terms';
import Sla from './pages/sla';
import Resources from './pages/resources';
import Success from './pages/success';
import SuccessSubscription from './pages/success_subscription';

function App() {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/about" element={<About />} />
      <Route exact path="/career" element={<Career />} />
      <Route exact path="/contact" element={<Contact />} />
      <Route exact path="/data_security" element={<Data_security />} />
      <Route exact path="/dpa" element={<Dpa />} />
      <Route exact path="/grow" element={<Grow />} />
      <Route exact path="/pricing" element={<Pricing />} />
      <Route exact path="/privacy" element={<Privacy />} />
      <Route exact path="/products" element={<Products />} />
      <Route exact path="/resources" element={<Resources />} />
      <Route exact path="/sla" element={<Sla />} />
      <Route exact path="/terms" element={<Terms />} />
      <Route exact path="/tricea" element={<Tricea />} />
      <Route exact path="/unique" element={<Unique />} />
      <Route exact path="/success" element={<Success />} />
      <Route exact path="/success-subscription" element={<SuccessSubscription />} />
    </Routes>
  );
}

export default App;
