import React from 'react'
import AboutNews from '../components/about_page/AboutNews'

const Grow = () => {
    return (
        <>
            <AboutNews />
        </>
    )
}

export default Grow