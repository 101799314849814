import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { SSRProvider } from 'react-bootstrap';
import Layout from './components/layout/Layout';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <SSRProvider>
      <Layout>
        <App />
      </Layout>
    </SSRProvider>
  </BrowserRouter>
);
