import React from 'react'
import AboutFooter from '../components/about_page/AboutFooter'
import AboutNews from '../components/about_page/AboutNews'
import AboutTeam from '../components/about_page/AboutTeam'
import Careers from '../components/about_page/Careers'
import WhyKrama from '../components/about_page/WhyKrama'
import AboutBanner from '../components/banners/AboutBanner'

const About = () => {
    return (
        <>
            <AboutBanner />
            <WhyKrama />
            <Careers />
            {/* <AboutNews /> */}
            <AboutTeam />
            <AboutFooter />
        </>
    )
}

export default About