import { useEffect, useState } from 'react'
import { axiosInstance } from '../axios/instance'
import HomeBanner from '../components/banners/HomeBanner'
import ActionPlan from '../components/home_page/ActionPlan'
import Cards from '../components/home_page/Cards'
import ContactForm from '../components/home_page/ContactForm'
import LatestUpdates from '../components/home_page/LatestUpdates'
import Partners from '../components/home_page/Partners'
import Review from '../components/home_page/Review'

export default function Home() {

  const [updates, setUpdates] = useState()

  const getUpdates = () => {
    axiosInstance.get('/content/latest_updates').then(response => {
      if (!response.data.error) {
        setUpdates(response.data.result)
      }
    }).catch(error => {
      console.log(error);
    })
  }

  useEffect(() => {
    getUpdates()
  }, [])
  return (
    <>
      <HomeBanner />
      <LatestUpdates data={updates} />
      <ActionPlan />
      <Cards />
      <ContactForm />
    </>
  )
}
