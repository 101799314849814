import React from 'react'

const Careers = () => {
    return (
        <div className="about-careers">
            <div className="content">
                {/* <span>Careers</span> */}
                <h2>We Simplify Complex Problems by Breaking them into Manageable Parts</h2>
                <p>The process of setting and tracking growth-oriented goals can be a complex task for businesses, 
                    especially those with large and diverse teams. With Calyber, we recognize this challenge and have 
                    developed an intuitive tool to simplify the goal-setting process by breaking them down it into smaller 
                    chunks. Our software allows businesses to set company-level, team-level, and individual-level objectives, 
                    and then break them down into smaller, measurable key results along with aligned, time-bound initiatives.</p>
                {/* <div className="button-full">
                    Join Our Team
                </div> */}
            </div>
        </div>
    )
}

export default Careers