import React from 'react'

const ResourcesBanner = () => {
  return (
    <div className="resources-banner">
        <div className="first-half">
            <h2>Deep dive into the world of Growth and Scaling technologies & development</h2>
            <br/>
            <p>Curated resources for your learning.</p>
        </div>
        <div className="second-half">
            <img src="/resource-banner.png" alt="" />
        </div>
    </div>
  )
}

export default ResourcesBanner