import React, { useEffect } from 'react'

const SuccessSubscription = () => {

    useEffect(() => {
        setInterval(() => {
            window.location.href = '/'
        }, [30000])
    }, [])
    return (
        <div className="container">
            <div className="latest-updates success" >
                <h2>Payment Successful</h2>
                <p style={{ textAlign: 'justify' }}>
                    Your onboarding process will be proceed through the registered email.
                </p>
                <p style={{ textAlign: 'justify' }}>
                    You will be automatically redirected to the home page or <a href='/'>Click here</a> to navigate..!
                </p>
                <p style={{ textAlign: 'justify' }}>
                    Thanks for using Calyber.
                </p>
            </div>
        </div >
    )
}

export default SuccessSubscription