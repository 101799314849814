import { faCheck, faMinus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const Features = ({ data }) => {
    return (
        <div className="container" id='price_feature'>
            <div className="pricing-features">
                <h3>Compare Features Across Plans</h3>
                <table>
                    <tr>
                        <th>&ensp;</th>
                        <th>&ensp;</th>
                        {data && data.map(item => (
                            <th key={item.id}>
                                <div className="head">
                                    <h4>{item.name}</h4>
                                    <h5>₹ {item.amount} /month</h5>
                                    <div className="button-full">Get Started</div>
                                </div>
                            </th>
                        ))}
                    </tr>
                    <tr>
                        <td><hr /></td>
                        <td><hr /></td>
                        <td><hr /></td>
                        <td><hr /></td>
                        <td><hr /></td>
                        <td><hr /></td>
                    </tr>
                    <tr>
                        <td><div className="note">&ensp;</div></td>
                        <td><div className="note">Create and manage OKRs</div></td>
                        <td><div className="note">&ensp;</div></td>
                        <td><div className="note">&ensp;</div></td>
                        <td><div className="note">&ensp;</div></td>
                        <td><div className="note">&ensp;</div></td>
                    </tr>
                    <tr>
                        <td><p><strong>Cycles and sessions</strong></p></td>
                        <td><p>Organize OKRs the way it makes sense for your organization. Create Sessions with set periods, then align OKRs between Sessions to understand how quarters contribute to strategic, annual OKRs. </p></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                    </tr>
                    <tr>
                        <td><p><strong>Multi-level OKRs and alignment</strong></p></td>
                        <td><p>Create OKRs at any level, from company to department, team, and individual.</p></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                    </tr>
                    <tr>
                        <td><p><strong>Built-in OKR templates</strong></p></td>
                        <td><p>Get inspired and learn by example by installing ready-to-go OKR templates from the App Marketplace.</p></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                    </tr>
                    <tr>
                        <td><p><strong>Multiple Key Result types</strong></p></td>
                        <td><p>Choose how you measure progress on a Key Result. You can measure it based on a numeric value, %, or a value of achieved or not. </p></td>
                        <td><FontAwesomeIcon icon={faMinus} color='gray' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                    </tr>
                    <tr>
                        <td><p><strong>Shared owners and collaborators</strong></p></td>
                        <td><p>OKRs can be owned by single teams and individuals or shared among multiple collaborators. </p></td>
                        <td><FontAwesomeIcon icon={faMinus} color='gray' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                    </tr>
                    <tr>
                        <td><hr /></td>
                        <td><hr /></td>
                        <td><hr /></td>
                        <td><hr /></td>
                        <td><hr /></td>
                        <td><hr /></td>
                    </tr>
                    <tr>
                        <td><div className="note">&ensp;</div></td>
                        <td><div className="note">Features</div></td>
                        <td><div className="note">&ensp;</div></td>
                        <td><div className="note">&ensp;</div></td>
                        <td><div className="note">&ensp;</div></td>
                        <td><div className="note">&ensp;</div></td>
                    </tr>
                    <tr>
                        <td><p><strong>OKRs</strong></p></td>
                        <td><p>description</p></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                    </tr>
                    <tr>
                        <td><p><strong>Reports</strong></p></td>
                        <td><p>description</p></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                    </tr>
                    <tr>
                        <td><p><strong>PMS</strong></p></td>
                        <td><p>description</p></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                    </tr>
                    <tr>
                        <td><p><strong>Bulk Uploads</strong></p></td>
                        <td><p>description</p></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                    </tr>
                    <tr>
                        <td><p><strong>Rewards</strong></p></td>
                        <td><p>description</p></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                    </tr>
                    <tr>
                        <td><p><strong>Reviews</strong></p></td>
                        <td><p>description</p></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                    </tr>
                    <tr>
                        <td><p><strong>CFR</strong></p></td>
                        <td><p>description</p></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                    </tr>
                    <tr>
                        <td><p><strong>History</strong></p></td>
                        <td><p>description</p></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                    </tr>
                    <tr>
                        <td><p><strong>Archives</strong></p></td>
                        <td><p>description</p></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                    </tr>
                    <tr>
                        <td><p><strong>Comparative Goal Setting</strong></p></td>
                        <td><p>description</p></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                    </tr>
                    <tr>
                        <td><p><strong>Collaboration Tracking</strong></p></td>
                        <td><p>description</p></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                    </tr>
                    <tr>
                        <td><p><strong>Weighted Scoring</strong></p></td>
                        <td><p>description</p></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                    </tr>
                    <tr>
                        <td><p><strong>Result Confidence Tracker</strong></p></td>
                        <td><p>description</p></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                    </tr>
                    <tr>
                        <td><hr /></td>
                        <td><hr /></td>
                        <td><hr /></td>
                        <td><hr /></td>
                        <td><hr /></td>
                        <td><hr /></td>
                    </tr>
                    <tr>
                        <td><div className="note">&ensp;</div></td>
                        <td><div className="note">Services and Supports</div></td>
                        <td><div className="note">&ensp;</div></td>
                        <td><div className="note">&ensp;</div></td>
                        <td><div className="note">&ensp;</div></td>
                        <td><div className="note">&ensp;</div></td>
                    </tr>
                    <tr>
                        <td><p><strong>Help center and knowledge base</strong></p></td>
                        <td><p>A rich library of videos and online materials to help your teams become proficient as OKRs practitioners and Gtmhub users. </p></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                    </tr>
                    <tr>
                        <td><p><strong>Standard support</strong></p></td>
                        <td><p>24/7 real-time customer support via phone, email, and chat with no more than 1 hour response time and 4-hour resolution times for critical issues. </p></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                    </tr>
                    <tr>
                        <td><p><strong>Enhanced 24/7 Support</strong></p></td>
                        <td><p>24/7 real-time customer support via phone, email, and chat with no more than 1 hour response time and 4-hour resolution times for critical issues. </p></td>
                        <td><FontAwesomeIcon icon={faMinus} color='gray' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                    </tr>
                    <tr>
                        <td><p><strong>Expert onboarding</strong></p></td>
                        <td><p>Let one of our partners or our Technical Success Managers guide you through the setup, integration, and implementation process.</p></td>
                        <td><FontAwesomeIcon icon={faMinus} color='gray' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                    </tr>
                    <tr>
                        <td><p><strong>Account manager and services</strong></p></td>
                        <td><p>A named Customer Success Manager ensures you get the most from your program, including check-ins, quarterly business reviews, and OKR cycle kickoffs. </p></td>
                        <td><FontAwesomeIcon icon={faMinus} color='gray' /></td>
                        <td><FontAwesomeIcon icon={faMinus} color='gray' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                        <td><FontAwesomeIcon icon={faCheck} color='green' /></td>
                    </tr>
                </table>
            </div>
        </div>
    )
}

export default Features