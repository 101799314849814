import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowUp } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useState } from 'react'
import Footer from '../footer/Footer'
import Header from '../header/Header'
import NarrowFooter from '../footer/NarrowFooter';

const initHeaders = (
    <Header />
);
const initFooters = (
    <Footer />
);
const initNarrowFooter = (
    <NarrowFooter />
)

const Layout = ({
    header = initHeaders,
    footer = initFooters,
    narrowFooter = initNarrowFooter,
    children
}) => {
    const [offset, setOffset] = useState(0);

    useEffect(() => {
        const onScroll = () => setOffset(window.pageYOffset);
        // clean up code
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, [])

    const backTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    return (
        <>
            {header}
            {children}
            {footer}
            {narrowFooter}
            {offset > 200 &&
                <div className="btn--container">
                    <div className='btn--backtop '>
                        <FontAwesomeIcon icon={faCircleArrowUp} size='lg' onClick={backTop} />
                    </div>
                </div>
            }
        </>
    );
};

export default Layout