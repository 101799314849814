import React from 'react'

const AboutNews = () => {
    return (
        <div className="container">
            <div className="about-us">
                {/* <div className="row"> */}
                {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12"> */}
                <div className="content">
                    {/* <span>world wide news</span> */}
                    <h2>Latest innovations in &ldquo;Grow and Scale&rdquo; technologies</h2>
                    <p style={{ textAlign: "left" }}>
                        More and more organizations continue to adopt agile and flexible management models like OKRs, OGSM etc.. And
                        continue to gain momentum in their effectiveness in driving goal setting, alignment, and accountability.
                        Here are some of the latest trends to note:
                    </p>
                    <p style={{ textAlign: "left" }}>
                        <b>Continuous Performance Management:</b> Organizations are increasingly moving away from traditional performance
                        reviews and adopting a more continuous approach to performance management. OKRs/OGSM etc provide a framework
                        for ongoing management of goals, tracking, feedback, and performance reviews to allow for more frequent and
                        meaningful conversations between managers and employees.
                    </p>
                    <p style={{ textAlign: "left" }}>
                        <b>Integrations with Other Tools:</b> Most cloud-based software today are offering integrations with other tools such
                        as project management, CRM, and HR systems. This allows for a more seamless flow of information and increased
                        visibility into performance, progress and results.
                    </p>
                    <p style={{ textAlign: "left" }}>
                        <b>Increased Focus on Employee Engagement:</b> One of the most neglected or diluted aspects of organization management
                        is effective engagement of employees. Employee engagement is a critical factor in the success of any goal
                        management framework. Organizations are focusing more on creating a culture of transparency, collaboration,
                        and recognition to foster real engagement and motivation.
                    </p>
                    <p style={{ textAlign: "left" }}>
                        <b>Data-Driven Decision Making:</b> The use of data analytics is becoming increasingly important in setting and
                        measuring performance parameter&apos;s. By leveraging data, organizations can make more informed decisions and
                        adjust their strategies in real-time based on actual results.
                    </p>
                    <p style={{ textAlign: "left" }}>
                        <b>Agile Goal Setting:</b> Agile methodologies are being applied to OKRs, allowing for more flexibility and adaptability
                        in goal setting. This approach involves setting shorter-term goals that can be adjusted as needed based on changing
                        circumstances and feedback.
                    </p>
                    <p style={{ textAlign: "left" }}>
                        OKRs for example can be seamlessly integrated with other organizational management/performance frameworks like lean, agile, scrum etc.
                    </p>
                    {/* </div> */}
                </div>
                {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div className="collage">
                            <img src="/about-image-2.png" alt="" />
                        </div>
                    </div> */}
                {/* </div> */}
            </div>
        </div>
    )
}

export default AboutNews