import React, { useEffect, useState } from 'react'
import { axiosInstance } from '../axios/instance'
import AboutFooter from '../components/about_page/AboutFooter'
import ResourcesBanner from '../components/banners/ResourcesBanner'
import Cs from '../components/common/Cs'
import Resourcesbooks from '../components/resources_page/Resourcesbooks'
import ResourcesBrands from '../components/resources_page/ResourcesBrands'
import ResourcesFooter from '../components/resources_page/ResourcesFooter'
import ResourcesForm from '../components/resources_page/ResourcesForm'
import ResourcesLibrary from '../components/resources_page/ResourcesLibrary'
import ResourcesSecondBanner from '../components/resources_page/ResourcesSecondBanner'
import ResourcesUniversity from '../components/resources_page/ResourcesUniversity'
import Testimonials from '../components/resources_page/Testimonials'

const Resources = () => {

  const [industries, setIndustries] = useState()
  const [functions, setFunctions] = useState()
  const [books, setBooks] = useState()
  const [resources, setResources] = useState()

  const getIndustries = () => {
    axiosInstance.get('/content/industries').then(response => {
      if (!response.data.error) {
        setIndustries(response.data.result)
      }
    })
  }
  const getFunctions = () => {
    axiosInstance.get('/content/functions').then(response => {
      if (!response.data.error) {
        setFunctions(response.data.result)
      }
    })
  }
  const getBooks = () => {
    axiosInstance.get('/content/books').then(response => {
      if (!response.data.error) {
        setBooks(response.data.result)
      }
    })
  }

  const getRsources = () => {
    axiosInstance.get('/content/resources').then(response => {
      if (!response.data.error) {
        setResources(response.data.result)
      }
    })
  }

  useEffect(() => {
    getIndustries()
    getFunctions()
    getBooks()
    getRsources()
  }, [])

  return (
    <>
          <ResourcesBanner />
          {/* <ResourcesBrands /> */}
          {/* <Testimonials /> */}
          <ResourcesLibrary industries={industries} functions={functions} />
          <ResourcesUniversity resources={resources} />
          <ResourcesSecondBanner />
          <Cs/>
          {/* <Resourcesbooks books={books} /> */}
          {/* <ResourcesForm /> */}
          {/* <ResourcesFooter /> */}
          <AboutFooter />
    </>
  )
}

export default Resources