import React from 'react'

const CareerBanner = () => {
    return (
        <div className="career-banner">
            <div className="content">
                <span>Careers</span>
                <h2>Join Our Team and Help Organizations Achieve Growth Through Effective Goal-Setting & Execution</h2>
                <p>Looking for a career where you can make a meaningful impact on businesses and organizations? 
                    Join our team and become part of the fast-growing field of OKRs .We are seeking passionate 
                    individuals who are driven to help organizations achieve their goals and thrive in a collaborative, 
                    results-oriented environment</p>
            </div>
        </div>
    )
}

export default CareerBanner