import React from 'react'

const DPAA = () => {
    return (
        <div className="container" id='dpaa'>
            <div className="policy px-1">
                <h2>Data Processing Agreement</h2>
                <p>Effective and Updated on April 1, 2023</p>
                <h4>Calyber – EU – Data Processing Agreement</h4>
                <p>This Data Processing Agreement (DPA) forms part of the Calyber.io Terms of Use (&ldquo;Principal Agreement&rdquo;) between:
                    (i) Calyber.io ​acting on its own behalf and as agent for each Calyber.io Affiliate; ​and (ii) Company ​acting on
                    its own behalf and as agent for each Company Affiliate.</p>
                <h3>1. Definitions</h3>
                <p>In this Agreement, the following terms shall have the meanings set out below and cognate terms shall be construed
                    accordingly:</p>
                <p>1.1. <b>&ldquo;Consent&rdquo;</b> of the Data Subject means any freely given, specific, informed, direct and unambiguous
                    indication of the Data Subject&apos;s wishes by which he or she, by a statement or by a clear affirmative action,
                    signifies agreement to the processing of personal data relating to him or her.</p>
                <p>1.2. <b>&ldquo;Controller&rdquo;</b> has the meaning given to it in the GDPR and shall be interpreted in the light of
                    rights and obligations thereof.</p>
                <p>1.3. <b>&ldquo;Data Protection Law&rdquo;</b> shall include the GDPR and any other applicable law, regulation and rules
                    for the time being in force.</p>
                <p>1.4. <b>&ldquo;Data Subject&rdquo;</b> has the same meaning and effect given to in the GDPR and shall include an
                    identifiable or identifiable natural person and shall be interpreted in the light of circumstances.</p>
                <p>1.5. <b>&ldquo;GDPR&rdquo;</b> means Regulation 2016/679 of the European Parliament and of the Council of 27 April 2016
                    on the protection of natural persons with regard to the Processing of Personal Data and on the free movement
                    of such data, and repealing Directive 95/46/EC (General Data Protection Regulation).</p>
                <p>1.6. <b>&ldquo;Personal Data&rdquo;</b> has the meaning given to it in GDPR and shall include any information relating
                    to an identified or identifiable natural person.</p>
                <p>1.7. <b>&ldquo;Processing&rdquo;</b> has the meaning given to it in the GDPR and &ldquo;process&rdquo;, &ldquo;processes&rdquo; and &ldquo;processed&rdquo;
                    will be interpreted accordingly.</p>
                <p>1.8. <b>&ldquo;Processor&rdquo;</b> has the meaning given to it in the GDPR and shall be interpreted in the light of
                    rights and obligations thereof.</p>
                <p>1.9. <b>&ldquo;Security Incident&rdquo;</b> means a breach of Processor&apos;s systems leading to accidental or unlawful
                    destruction, alteration, unauthorized access or any damage caused to Personal Data provided by controller
                    for Processing.</p>
                <p>1.10. Terms otherwise not defined under this Agreement shall be interpreted in light of the meanings
                    assigned to them under the GDPR.</p>

                <br />

                <h3>2. Purpose and Scope</h3>
                <p>2.1. The Processor shall process Personal Data for the limited purpose of performing the obligations
                    set out under the Agreement or within the scope of written lawful documented instructions provided
                    from time to time by the Controller.</p>
                <p>2.2. The term of this Agreement shall continue until the later of the following:</p>
                <p className='small'>2.2.1. The termination of the Agreement;</p>
                <p className='small'>2.2.2. The date at which the Data Processor ceases to process Personal Data for the Data Controller.</p>
                <p>2.3. The Personal Data to be processed by the Processor for purposes of the Processing set out in Clause
                    3 in this Agreement.</p>

                <br />

                <h3>3. Processing Operations</h3>
                <p>3.1. The Personal Data shall be processed in accordance with this Agreement and may be subject to the
                    following Processing activities.</p>
                <p className='small'>3.1.1. Storage and Processing of data using approved third party services, wherever needed, necessary to
                    provide and maintain services subscribed by the Controller.</p>
                <p className='small'>3.1.2. Disclosures permissible by GDPR and in accordance with this Agreement or as authorised by Controller
                    in writing.</p>
                <p>3.2. The Parties shall comply with all laws, regulations and rules applicable to its performance under
                    this Agreement.</p>

                <br />

                <h3>4. Confidentiality of Data</h3>
                <p>4.1. The Processor shall not access or disclose data provided by the Controller to any third party in the
                    European Economic Area (&ldquo;EEA&rdquo;) which will provide for hosting of the services, except to the extent
                    necessary for provision of services or maintenance under this Agreement or as necessary to comply
                    with the law in force. The obligations of such third parties are envisaged in a separate data processing
                    agreement which is within the framework of this Agreement. All data in the service shall be stored on
                    servers located in Europe.</p>
                <p>4.2. The Processor shall implement policies and impose contractual obligations on its personnel regarding
                    data protection, data security and confidentiality. Failure to comply with the same will lead to
                    termination of this Agreement with immediate effect.</p>

                <br />

                <h3>5. Obligations of the Data Processor</h3>
                <p>5.1. The Processor agrees and warrants to:</p>
                <p>5.2. To process Personal Data only on behalf of the Controller while complying with the terms of the Agreement
                    and the Data Protection Law;</p>
                <p>5.3. Process any Personal Data transferred to or collected by the Data Processor only as a ‘processor&apos;, as such
                    terms are defined in the Data Protection Law on behalf of the Data Controller;</p>
                <p>5.4. Implement appropriate technical and organizational measures and follow established routines in such a
                    manner that Processing will meet the requirements of the applicable Data Protection Law and ensure the
                    protection of the rights of the Data Subjects;</p>
                <p>5.5. To deal promptly and properly with requests and inquiries of the Data Controller;</p>
                <p>5.6. Assist the Data Controller in ensuring compliance with the requirements for security of Personal Data;</p>
                <p>5.7. On a regular basis or on the demand of the Controller, to carry out third party security audits for
                    systems and similar relevant for the Processing of Personal Data and the reports documenting such
                    security audits shall be available to the Controller;</p>
                <p>5.8. Take into account the nature of the Processing, assist the Data Controller by appropriate technical
                    and organizational measures, in so far as this is possible, for the fulfilment of the Data Controller&apos;s
                    obligation to respond to requests for exercising the Data Subject&apos;s rights according to the Data Protection Law;</p>
                <p>5.9. Make available to the Data Controller all information necessary to demonstrate compliance with the
                    obligations laid down in this Agreement and to allow for, co-operate and contribute to audits, including
                    inspections to facilities under the control of the Data Processor, conducted by the Controller or an
                    auditor mandated by the Controller and provide access to data systems;</p>
                <p>5.10. Promptly notify:</p>
                <p className='small'>5.10.1. Any binding request for disclosure of Personal Data made by law enforcement authorities under the
                    law in force unless otherwise explicitly prohibited under criminal law to preserve the confidentiality of
                    an investigation;</p>
                <p className='small'>5.10.2. Any breach, accidental, lawful or unauthorised access; and</p>
                <p className='small'>5.10.3. Any request received directly from the Data Subjects unless it has been authorised;</p>
                <p>5.11. To send promptly a copy of any sub-processor agreement it concludes to the Controller;</p>
                <p>5.12. Ensure that its Sub-processors involved in the Processing of Personal Data at all times comply with
                    the obligations and subject to the limitations set forth herein above;</p>
                <p>5.13. To ensure confidentiality and non-disclosure of Personal Data at all times;</p>
                <p>5.14. To implement and maintain appropriate technical and organizational measures to protect Personal Data
                    from Security Incidents and not to update or modify the security measures provided that such modification
                    or update does not result in a material degradation in the protection offered to Personal Data.</p>

                <br />

                <h3>6. Obligation of Data Controller</h3>
                <p>6.1. The Controller agrees and warrants to:</p>
                <p>6.2. The Data Controller will be separately responsible for complying with the Data Protection Law as
                    applicable to them</p>
                <p>6.3. Ensure that the Processing of Personal Data which the Data Processor is instructed to perform has a
                    legal basis and has been obtained as per the Data Protection Law</p>
                <p>6.4. Instruct the Processor to process the Personal Data transferred only on the Controller&apos;s behalf and
                    in accordance with the applicable Data Protection Law</p>
                <p>6.5. Ensure compliance with security measures relevant to the contract between the parties and data being
                    shared under it.</p>
                <p>6.6. Inform the Controller of the transmission of special categories of data prior to data transfer for
                    Processing</p>
                <p>6.7. The Data Controller shall inform the Data Processor in writing without undue delay following the Data
                    Controller&apos;s discovery of failure to comply with Data Protection Law and/or Agreement with respect to
                    Processing of Personal Data in connection with data shared between the parties.</p>
                <p>6.8. Shall be responsible for providing accurate and relevant information to the Processor after entering
                    into the Agreement and thereafter to assist in Data Processor&apos;s notification obligations relating to data
                    and processing between the parties.</p>

                <br />

                <h3>7. Assistance to controller</h3>
                <p>7.1. The Processor undertakes to provide timely assistance to the Controller in respect of:</p>
                <p className='small'>7.1.1. Any request from a data subject to exercise any of its rights under data protection law;</p>
                <p className='small'>7.1.2. Inquiries and complaints of Data Subjects in connection with Processing of Personal Data;</p>
                <p className='small'>7.1.3. Request from data protection authorities relating to Processing of Personal Data.</p>
                <p>7.2. The Processor shall co-operate and provide reasonable assistance to the Controller for conduction of any
                    data protection impact assessments and prior consultation with supervisory authorities or other competent
                    data privacy authorities.</p>

                <br />

                <h3>8. Security of Data Processing</h3>
                <p>8.1. The Processor shall undertake:</p>
                <p className='small'>8.1.1. Implementation and maintenance of technical and organizational security measures during the subsistence
                    of this Agreement to protect Personal Data against any unauthorized disclosure and appropriation and against
                    unlawful destruction, loss, damage;</p>
                <p className='small'>8.1.2. Reasonable steps including third party background checks to ensure the reliability of any employee,
                    agent or contractor who may have access to the Personal Data, ensuring in each case that access is strictly
                    limited on a need to know / access basis strictly for the purposes of this Agreement, and to comply with
                    applicable laws, company guidelines and ethical standards and ensure that such individuals are subject to
                    confidentiality undertakings and/or statutory obligations of confidentiality;</p>
                <p className='small'>8.1.3. Regular testing, assessing and evaluating the effectiveness of the technical and organizational
                    measures implemented for protection of Personal Data.</p>
                <p>8.2. The Processor should implement technical and organizational measures in relation to:</p>
                <p className='small'>8.2.1. Pseudonymisation and encryption to ensure an appropriate level of security;</p>
                <p className='small'>8.2.2. Measures to ensure the ongoing confidentiality, integrity, availability and resilience of the Processing
                    systems and services that are being operated by the Controller;</p>
                <p className='small'>8.2.3. Measures to allow Customer to backup and archive appropriately in order to restore availability and access
                    to Personal Data in a timely manner in the event of a physical or technical incident;</p>
                <p className='small'>8.2.4. Measures to prevent network traffic using unauthorized protocols from reaching the product infrastructure;</p>
                <p className='small'>8.2.5. Processes for regularly testing, assessing and evaluating the effectiveness of the technical
                    and organizational security measures implemented by Controller.</p>

                <br />

                <h3>9. Personal Data Breach</h3>
                <p>9.1. In an event of Security Incident the Processor shall:</p>
                <p className='small'>9.1.1. Notify Controller of the Security Incident without undue delay after becoming aware of the Security
                    Incident within 24 hours; and</p>
                <p className='small'>9.1.2. Take reasonable steps to mitigate the effects to minimise any damage resulting from the Security Incident.</p>
                <p>9.2. The notification referred to in Clause 8.1 shall include:</p>
                <p className='small'>9.2.1. Description of the nature of the Personal Data breach including qualitative and quantitative
                    characteristics of the data breached;</p>
                <p className='small'>9.2.2. Communicate the name and contact details of the person with the Data Processor where more
                    information can be obtained regarding the Security Incident;</p>
                <p className='small'>9.2.3. Describe the likely consequences of the Personal Data breach;</p>
                <p className='small'>9.2.4. Describe the measures taken or proposed to be taken by the Data Processor to address the Personal
                    Data breach, including, where appropriate, measures to mitigate its possible adverse effects.</p>
                <p>9.3. In an event of security breach, the Processor shall assist the Controller to make notifications in
                    compliance with the Data Protection Law.</p>

                <br />

                <h3>10. Sub-Processors</h3>
                <p>10.1. The Processor may use sub-processors to fulfil its contractual obligations under this Agreement and
                    to provide certain services on its behalf to the Controller.</p>
                <p>10.2. The Processor shall ensure that sub-processors undertake to process Personal Data in accordance with
                    this Agreement and applicable Data Protection Law.</p>
                <p>10.3. The Processor shall ensure that its sub-processors shall implement and maintain the security of
                    Data Processing in accordance with Clause 5.1 of the Agreement.</p>
                <p>10.4. Any transfer of Personal Data to third countries or international organizations by the Data Processor
                    for the process of sub-Processing shall only occur on the basis of documented instructions from the Controller
                    and shall always take place in compliance with Chapter V GDPR.</p>

                <br />

                <h3>11. Obligations of Sub-Processors</h3>
                <p>11.1. The Processor shall enter into written agreements with sub-processors and to the extent that the
                    sub-processor is performing the same data Processing services that are being provided by the Processor
                    under this Agreement, the Processor shall/will impose on the sub-processor the same contractual obligations
                    and restrictions that the Processor is bound by under this Agreement.</p>
                <p>11.2. The sub-processor shall access data in accordance with the permissions provided by the Processor to
                    the extent of fulfilling its obligations under the sub-Processing agreements.</p>
                <p>11.3. The Processor shall be liable for acts or omissions of the sub-processors breaching the obligations
                    of the processor under this Agreement.</p>

                <br />

                <h3>12. Liability to Data Subjects</h3>
                <p>12.1. Each party&apos;s liability towards Data Subjects shall be to the extent of their acts and omissions
                    contributing to the violation of Data Subject rights under the Data Protection Law.</p>

                <br />

                <h3>13. Liability for Breach of Data Protection Provisions of the Contract</h3>
                <p>13.1. The party committing the breach of contract shall be liable to pay actual and proven damages to the
                    affected party and/or perform its obligations under this contract to make good any actual and direct losses.</p>

                <br />

                <h3>14. Data Transfer</h3>
                <p>14.1. The Controller must be informed of data transfers prior to such transfer. The processor must notify the
                    controllers of new transfers at least 4 weeks in advance. The Controller must inform their concerns within 20
                    working days for any objection through written communication. Should the Controller reject such transfer of
                    Personal Data the Processor is obligated to cooperate with the Controller in order not to transfer data.</p>
                <p>14.2. The transmission of data shall be done in accordance with the relevant data protection law applicable to
                    the importing and exporting jurisdictions.</p>
                <p>14.3. Any transfer of Personal Data to third countries or international organizations by the Processor shall
                    only occur on the basis of documented instructions from the Controller and shall always take place in compliance
                    with Chapter V GDPR.</p>
                <p>14.4. If the transfer involves sensitive categories of data then the Consent of the Data Subject shall be
                    obtained by either party for the transmission of the data to a third country not providing adequate protection
                    within the meaning of the GDPR.</p>

                <br />

                <h3>15. Deletion or Return of Personal Data</h3>
                <p>15.1. The Processor shall provide the Controller the recourse of deletion and retrieval of Personal Data which
                    had been provided for fulfilling the purposes of this Agreement.</p>

                <br />

                <h3>16. The Processor shall provide the Controller the recourse of deletion and retrieval of Personal Data which
                    had been provided for fulfilling the purposes of this Agreement.</h3>
                <p>16.1. This Agreement shall remain in full force and effect unless amended, terminated or deemed unenforceable
                    under the law in force. In an event of conflict with the law in force, the Agreement shall be deemed to be
                    unenforceable to the extent it is in conflict with the law only for the specific terms found to be unenforceable.
                    The remaining terms within this agreement will remain in force.</p>
            </div>
        </div>
    )
}

export default DPAA