import { Link } from 'react-router-dom'
import React, { useState } from 'react'
import Paypal from '../paypal/Paypal'
import { Modal } from 'react-bootstrap'
import Payment from '../stripe/Payment'
import { axiosInstance } from '../../axios/instance'

const Resourcesbooks = ({ books }) => {
    const [show, setShow] = useState(false)
    const [paypalWindow, setPaypalWindow] = useState(false)
    const [stripeWindow, setStripeWindow] = useState(false)
    const [item, setItem] = useState()

    const backTop = (e) => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        setShow(true)
        // handleSubmit(e)
    }

    const handlePayment = (data) => {
        // setItem(data)
        // setStripeWindow(true)
        axiosInstance
            .post(`/stripe/create-checkout-session`, {
                cartItems: [data],
                userId: 0,
            })
            .then((response) => {
                if (response.data.url) {
                    window.location.href = response.data.url;
                }
            })
            .catch((err) => console.log(err.message));
    }

    return (
        <div className="container">
            <div className="resources-books">
                <div className="cards">
                    {books && books.map(item => (
                        <div className="card" key={item.id}>
                            <div className="image-section">
                                <img src={item.image_path} alt="" />
                            </div>
                            <div className="content">
                                <Link to={`/detail/-${item.id}`} >
                                    <h4>{item.title}</h4>
                                </Link>
                                <p>{item.description}</p>
                                <div className="row px-2">
                                    <div className="button-full">
                                        Download
                                    </div>
                                    {item.amount && item.amount != 0 &&
                                        <div className="button-empty mx-2" onClick={() => handlePayment(item)}>
                                            Buy
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Modal className='modal_stripe' show={stripeWindow} centered onHide={() => setStripeWindow(false)}>
                <div className="p-5">
                    <Payment item={item && item} />
                </div>
            </Modal>
        </div>
    )
}

export default Resourcesbooks