import React from 'react'

const WhyKrama = () => {
    return (
        <div className="container" id='about_us'>
            <div className="why-krama">
                    <p style={{ textAlign: 'justify' }}>
                        At Calyber,  we understand that growth and scaling for an organization is a complex and multifaceted
                        endeavor - even more so, after the pandemic of 2020. That&apos;s where our tool becomes a holistic solution that
                        helps businesses of all sizes and types improve their organization&apos;s scalability, visibility, and
                        business performance.
                    </p>
                    <p style={{ textAlign: 'justify' }}>
                        Our software is the result of years of research and development and has been designed by an award-winning subject
                        matter expert to be intuitive, user-friendly, and highly effective. It boasts of a wide range of tools and
                        features that are customizable to your unique needs and goals, including organization design, employee
                        alignment, performance analytics, process optimization, data, and metrics comparisons, tracking team
                        deliverables and much more.
                    </p>
                    <p style={{ textAlign: 'justify' }}>
                        Calyber is more than just a collection of features. We believe that the key to success lies in collaboration
                        and communication, which is why we prioritize working closely with our clients to understand their challenges
                        and develop tailored solutions. Our expert team of developers, analysts, and consultants are committed to
                        providing exceptional customer service and support, and are available to answer questions and provide guidance
                        at every step of the way.
                    </p>
                </div>
        </div>
    )
}

export default WhyKrama