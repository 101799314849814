import React from 'react'

const ProductManagement = () => {
    return (
        <div className="container" id='org_management'>
            <div className="product-management">
                <span>ORGANIZATION MANAGEMENT</span>
                <h2>Say Hello to our Product</h2>
                <p> Here&apos;s a sneak peek of our revolutionary platform - Transform the way you set and achieve goals.</p>
                <div className="carousel">
                    <div className="yellow-bg"></div>
                    <div className="content">
                        <img src="/100.gif" alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductManagement