import React, { useEffect, useState } from 'react'
import { axiosInstance } from '../../axios/instance'
import { toast, ToastContainer } from 'react-toastify';
import PhoneInput from 'react-phone-number-input';
import ReCAPTCHA from 'react-google-recaptcha';

const ContactForm = () => {
    const [firstName, setFirstName] = useState()
    const [firstNameErr, setFirstNameErr] = useState()
    const [lastName, setLastName] = useState()
    const [lastNameErr, setLastNameErr] = useState()
    const [phone, setPhone] = useState()
    const [phoneErr, setPhoneErr] = useState()
    const [email, setEmail] = useState()
    const [emailErr, setEmailErr] = useState()
    const [jobType, setJobType] = useState()
    const [jobTypeErr, setJobTypeErr] = useState()
    const [company, setCompany] = useState()
    const [companyErr, setCompanyErr] = useState()
    const [companySize, setCompanySize] = useState()
    const [companySizeErr, setCompanySizeErr] = useState()
    const [comments, setComments] = useState()
    const [commentsErr, setCommentsErr] = useState()

    const [verify, setVerify] = useState(false)

    const notify = (notification) => toast(notification, {
        autoClose: 1000
    });

    const notifyErr = (notification) => toast.error(notification, {
        autoClose: 1000
    });

    const notifyWarning = (notification) => toast.warning(notification, {
        autoClose: 1000
    });

    useEffect(() => {
        if (firstName)
            setFirstNameErr()
        if (lastName)
            setLastNameErr()
        if (phone)
            setPhoneErr()
        if (email)
            setEmailErr()
        if (jobType)
            setJobTypeErr()
        if (company)
            setCompanyErr()
        if (companySize)
            setCompanySizeErr()
        if (comments)
            setCommentsErr()
    }, [firstName, lastName, phone, email, jobType, comments, company, companySize])

    const requestDemo = () => {
        if (verify) {
            if (firstName, lastName, phone, email, jobType, company, companySize, comments) {
                axiosInstance.post('/enquiry/request_demo', {
                    firstName,
                    lastName,
                    phone,
                    email,
                    jobType,
                    company,
                    companySize,
                    comments
                }).then(response => {
                    if (!response.data.error) {
                        notify('Your query submitted successfully, will get back to you soon.')
                        setFirstName('')
                        setLastName('')
                        setPhone()
                        setEmail('')
                        setJobType('')
                        setCompany('')
                        setCompanySize('')
                        setComments('')
                    } else {
                        notify('Unexpected error occured')
                    }
                }).catch(error => {
                    console.log(error);
                })
            } else {
                if (!firstName)
                    setFirstNameErr('First name required')
                if (!lastName)
                    setLastNameErr('Last name required')
                if (!phone)
                    setPhoneErr('Phone number required')
                if (!email)
                    setEmailErr('Email required')
                if (!jobType)
                    setJobTypeErr('Job title required')
                if (!company)
                    setCompanyErr('Company required')
                if (!companySize)
                    setCompanySizeErr('Company size required')
                if (!comments)
                    setCommentsErr('Comments required')
            }
        } else {
            notifyWarning('Verify that you are human.')
        }
    }
    return (
        <div className="container" id='request_demo'>
            <ToastContainer />
            <div className="contact-form">
                <div className="yellow-bg"></div>
                <div className="form">
                    <h2>Request a Demo</h2>
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 column">
                            <label htmlFor="">first name <span>*</span></label>
                            <input type="text" placeholder='Enter your first name' value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                            <label htmlFor=""><span>{firstNameErr && firstNameErr}</span></label>
                            <label htmlFor="">job title <span>*</span></label>
                            <input type="text" placeholder='Enter your job title' value={jobType} onChange={(e) => setJobType(e.target.value)} />
                            <label htmlFor=""><span>{jobTypeErr && jobTypeErr}</span></label>
                            <label htmlFor="">phone number <span>*</span></label>
                            <PhoneInput
                                placeholder="Enter phone number"
                                value={phone}
                                onChange={setPhone}
                                style={{ width: '90%' }}
                            />
                            <label htmlFor=""><span>{phoneErr && phoneErr}</span></label>
                            <label htmlFor="">company name <span>*</span></label>
                            <input type="text" placeholder='Enter the name of your organization' value={company} onChange={(e) => setCompany(e.target.value)} />
                            <label htmlFor=""><span>{companyErr && companyErr}</span></label>
                            <label htmlFor="">company size </label>
                            <input type="number" placeholder='Select the strength of your organization' value={companySize} onChange={(e) => setCompanySize(e.target.value)} />
                            <label htmlFor=""><span>{companySizeErr && companySizeErr}</span></label>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 column">
                            <label htmlFor="">last name </label>
                            <input type="text" placeholder='Enter your last name' value={lastName} onChange={(e) => setLastName(e.target.value)} />
                            <label htmlFor=""><span>{lastNameErr && lastNameErr}</span></label>
                            <label htmlFor="">work email address </label>
                            <input type="text" placeholder='Enter your email address' value={email} onChange={(e) => setEmail(e.target.value)} />
                            <label htmlFor=""><span>{emailErr && emailErr}</span></label>
                            <label htmlFor="">comments </label>
                            <textarea type="text" placeholder='Enter your message' value={comments} onChange={(e) => setComments(e.target.value)} />
                            <label htmlFor=""><span>{commentsErr && commentsErr}</span></label>
                            <ReCAPTCHA
                                sitekey='6LeR92QmAAAAAGbgjrwXSeQCjMz0O8jqQoYsZ7OL'
                                onChange={() => setVerify(true)}
                            />
                            <br />
                            <div className="button-full" onClick={requestDemo}>
                                Request Demo
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactForm