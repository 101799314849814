import React from 'react'

const SLAA = () => {
    return (
        <div className="container" id='slaa'>
            <div className="terms px-5">
                <h2>Service Level Agreement</h2>
                <p>Calyber is constantly working to improve its commitment to quality customer support. We kindly ask you
                    to help us respond to your requests efficiently by providing all relevant information about the problem.</p>
                <p>Calyber.io&apos;s service level guarantees for uptime, system response and issue response/resolution are
                    as follows:</p>
                <h4>Uptime/Availability</h4>
                <p>Calyber.io services have an uptime guarantee of 99.9%.</p>
                <p>In case of planned downtimes for maintenance, Calyber.io will give at least 2 weeks of notice. Our maintenance
                    activities are usually performed on Saturdays.</p>
                <h4>In case of planned downtimes for maintenance, Calyber.io will give at least 2 weeks of notice. Our maintenance
                    activities are usually performed on Saturdays.</h4>
                <p>Calyber.io prides in building and rolling out products with great user experience, including good system response
                    times. On the cloud instance, we strive for UX responses to be less than 2 seconds on average, without
                    considering tracking requests that are loaded in the footer, and hence doesn&apos;t impact user experience.</p>
            </div>
        </div>
    )
}

export default SLAA