import React from 'react'

const Partners = () => {
  return (
    <div className="container">
        <div className="partners">
            <img src='/partners.png' alt=''/>
        </div>
    </div>
  )
}

export default Partners