import React from 'react'

const ResourcesUniversity = ({ resources }) => {
    return (
        <div className="resources-university" id='rsr_academy'>
            <div className="container">
                <span>Lerned Academy™</span>
                <h2>Learn more. About EVERYTHING that matters!</h2>
                <p>
                    A perfect place to discover everything you need for optimal personal and professional development,
                    including OKR coach certification courses.
                </p>
                <p>
                    Explore our comprehensive collection of expertise-building sessions by globally-recognized experts,
                    to help you understand and master your growth trajectory.  Our masterclasses and deep-dive sessions are
                    designed for individuals, teams, and organizations who want to achieve their growth goals more effectively
                    and efficiently.
                </p>
                <p>
                    Start learning today. With us.<br />
                    And take the first step towards your journey to become LERNED.
                </p>
                <div className="cards">
                    {resources && resources.map(item => (
                        <div className="card" key={item.id}>
                            <div className="image-section">
                                <img src={item.image_path} alt="" />
                            </div>
                            <div className="content">
                                <h4>{item.title}</h4>
                                <p>{item.description}</p>
                                {/* <div className="button-full">
                                    Read More
                                </div> */}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default ResourcesUniversity