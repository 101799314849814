import { faFacebook, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const AboutTeam = () => {
    return (
        <div className="container" id='team'>
            <div className="about-us">
                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div className="image-cards">
                            <img src="/team.jpg" alt="" />
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div className="content">
                            <span>team</span>
                            <h2>Engage and Collaborate Effectively with your Team for Growth</h2>
                            <p>
                                Calyber is designed to help businesses engage and collaborate with their teams more effectively.
                                With features such as team goal-setting, progress tracking, and real-time feedback, conversation
                                setups, reviews etc, our tool enables businesses to drive collaboration and communication across
                                the organization. businesses can break down silos and foster a culture of transparency and accountability.
                            </p>
                            <p>
                                In addition, our tool provides features that enable teams to give and receive real-time feedback,
                                facilitating continuous improvement and growth. By promoting collaboration and feedback, businesses
                                can create a culture of learning and development, which is critical for long-term success.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutTeam