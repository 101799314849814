import React from 'react'

const ProductCards2 = () => {
    return (
        <div className="container">
            <div className="product-cards-2">
                <div className="cards">
                    <div className="details">
                        <div className="number">
                            <div className="content">1</div>
                        </div>
                        <h6>Create and prioritize tasks</h6>
                        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. </p>
                    </div>
                </div>
                <div className="cards">
                    <div className="details">
                        <div className="number">
                            <div className="content">2</div>
                        </div>
                        <h6>Collaborate teams with Customized workspace</h6>
                        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. </p>
                    </div>
                </div>
                <div className="cards">
                    <div className="details">
                        <div className="number">
                            <div className="content">3</div>
                        </div>
                        <h6>Checklists with stages to specify project flow</h6>
                        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductCards2