import { faPlayCircle } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const Testimonials = () => {
    return (
        <div className="container">
            <div className="testimonials">
                <span>Testimonials</span>
                <h2>Hear from our happy customers</h2>
                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.</p>
                <div className="cards">
                    <div className="card">
                        <div className="image-section">
                            <img src="/testimony-1.png" className='img-1' alt="" />
                            <img src="/arrow.png" className='img-arrow' alt="" />
                            <div className="play-button">
                                <FontAwesomeIcon icon={faPlayCircle} color='orange' size='2xl' />
                            </div>
                        </div>
                        <div className="content">
                            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim</p>
                            <img src="/review-logo.png" alt="" />
                            <span><b>Amit Dixit</b>, CEO</span>
                        </div>
                    </div>
                    <div className="card">
                        <div className="image-section">
                            <img src="/testimony-1.png" className='img-1' alt="" />
                            <img src="/arrow.png" className='img-arrow' alt="" />
                            <div className="play-button">
                                <FontAwesomeIcon icon={faPlayCircle} color='orange' size='2xl' />
                            </div>
                        </div>
                        <div className="content">
                            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim</p>
                            <img src="/review-logo.png" alt="" />
                            <span><b>Amit Dixit</b>, CEO</span>
                        </div>
                    </div>
                    <div className="card">
                        <div className="image-section">
                            <img src="/testimony-1.png" className='img-1' alt="" />
                            <img src="/arrow.png" className='img-arrow' alt="" />
                            <div className="play-button">
                                <FontAwesomeIcon icon={faPlayCircle} color='orange' size='2xl' />
                            </div>
                        </div>
                        <div className="content">
                            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim</p>
                            <img src="/review-logo.png" alt="" />
                            <span><b>Amit Dixit</b>, CEO</span>
                        </div>
                    </div>
                </div>
                <div className="button-full">
                    View More
                </div>
            </div>
        </div>
    )
}

export default Testimonials