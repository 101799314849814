import { Link } from 'react-router-dom'
import React from 'react'
import { HashLink } from 'react-router-hash-link'

const HomeBanner = () => {
  return (
    <div className="home-banner">
      <div className="first-half">
        <div className="content">
          <h2>CALYBER®</h2>
          <p><strong>The Award-winning Growth Management Software. Build your Growth Story.</strong></p>
          <p><i>Amplify your organization&apos;s productivity, performance, and growth. Transform your organization.</i></p>
          <div className="buttons">
            <HashLink to='/#request_demo'>
              <div className="button-full">Let&lsquo;s Start</div>
            </HashLink>
          </div>
        </div>
      </div>
      <div className="second-half">
        <img src="/150.gif" alt="" />
      </div>
    </div>
  )
}

export default HomeBanner