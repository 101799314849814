import React from 'react'

const ResourcesFooter = () => {
  return (
    <div className="about-footer">
        <h2>Execute your strategy with our OKR software</h2>
        <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. At quo assumenda laudantium delectus blanditiis, incidunt voluptatum molestias expedita provident iusto tempora ipsa eius quis facilis ad ab aspernatur distinctio molestiae.</p>
        <div className="button-full">Request a Demo</div>
    </div>
  )
}

export default ResourcesFooter