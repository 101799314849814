import React from 'react'
import {
    useJsApiLoader,
    GoogleMap,
    Marker
} from '@react-google-maps/api'
import { useRef } from 'react'

const Maps = () => {
    const googleMapsApiKey = 'AIzaSyA9dTOobVnPExbxhmi-GP4KMl3V_Oe2sD0'
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey,
        libraries: ['places'],
    })
    const center = {
        lat: 12.523629073998107,
        lng: 77.37536394152932
    }

    /** @type React.MutableRefObject<HTMLInputElement> */
    const originRef = useRef()
    if (!isLoaded) {
        return (
            <div className="row justify-center p-5">
                <p>Loading</p>
            </div>
        )
    }
    return (
        <GoogleMap
            center={center}
            zoom={12}
            mapContainerStyle={{ width: '100%', height: '100%' }}
            options={{
                zoomControl: false,
                streetViewControl: false,
                mapTypeControl: false,
                fullscreenControl: false,
            }}
        >
            <Marker position={center} />
        </GoogleMap>
    )
}

export default Maps