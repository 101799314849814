import { faLinkedin, faTwitter, faWhatsapp, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faGlobe, faMapLocation, faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { HashLink } from 'react-router-hash-link'

const Footer = () => {
    return (
        <div className="footer" id='footer'>
            <div className="info">
                <div className="head">
                    <img src="/logo.png" alt="" style={{ width: '70px', height: '70px' }} />
                    {/* <h4>CALYBER</h4> */}
                </div>
                <p>Reach us Monday - Friday<br />9am - 6pm (UTC+5.30)</p>
                <div className="link">
                    <div className="icon">
                        <FontAwesomeIcon icon={faEnvelope} color='orange' />
                    </div>
                    <p>ceo@calyber.io</p>
                </div>
                <div className="link">
                    <div className="icon">
                        <FontAwesomeIcon icon={faMapLocation} color='red' />
                    </div>
                    <p>SRAGVEE TECHNOLOGIES PRIVATE LIMITED</p>
                </div>
	    	<div className="link">
                    <div className="icon">
                        <FontAwesomeIcon icon='' color='orange' />
                    </div>
                    <p>177, PUJA ELITE, 1st Main Road, Vittalnagar, Bengaluru - 560078</p>
                </div>
                {/* <div className="icons">
                    <div className="icon">
                        <FontAwesomeIcon icon={faLinkedin} color='yellow' />
                    </div>
                    <div className="icon">
                        <FontAwesomeIcon icon={faYoutube} color='red' />
                    </div>
                    <div className="icon">
                        <FontAwesomeIcon icon={faWhatsapp} color='orange' />
                    </div>
                    <div className="icon">
                        <FontAwesomeIcon icon={faTwitter} color='yellow' />
                    </div>
                </div> */}
            </div>
            <div className="links">
                <div className="section">
                    <span><HashLink to='/#header'>Home</HashLink></span>
                    <p><HashLink to='/#latest'>Latest Updates</HashLink></p>
                    <p><HashLink to='/#strategizing'>Successful Strategizing</HashLink></p>
                    <br />
                    <span><HashLink to='/about/#header'>About</HashLink></span>
                    <p><HashLink to='/about/#about_us'>About</HashLink></p>
                    <p><HashLink to='/about/#team'>Teams</HashLink></p>
                </div>
                <div className="section">
                    <span><HashLink to='/products/#header'>Products</HashLink></span>
                    <p><HashLink to='/products/#product_overview'>Overview</HashLink></p>
                    <p><HashLink to='/products/#org_management'>Organization Management</HashLink></p>
                    <p><HashLink to='/products/#emp_management'>Employee Management</HashLink></p>
                    <br />
                    <span><HashLink to='/pricing/#header'>Pricing</HashLink></span>
                    {/* <p><HashLink to='/pricing/#price_plans'>Pricing Plans</HashLink></p>
                    <p><HashLink to='/pricing/#price_feature'>Compare Features</HashLink></p> */}
                </div>
                <div className="section">
                    <span><HashLink to='/resources/#header'>Resources</HashLink></span>
                    <p><HashLink to='/resources/#rsr_library'>OKR Library</HashLink></p>
                    <p><HashLink to='/resources/#rsr_academy'>Calyber Academy</HashLink></p>
                    <p><HashLink to='/resources/#rsr_buy'>Download / Buy</HashLink></p>
                    <br />
                    <span><HashLink to='/career'>Career</HashLink></span>
                </div>
                {/* <div className="section">
                    <span><HashLink to='/contact/#header'>Contact</HashLink></span>
                    <p><HashLink to='/contact/#support'>Support</HashLink></p>
                    <p><HashLink to='/contact/#faq'>FAQs</HashLink></p>
                    <p><HashLink to='/contact/#request_demo'>Request a Demo</HashLink></p>
                </div> */}
                <div className="section">
                    <span><HashLink to='#'>Policy pages</HashLink></span>
                    <p><HashLink to='/terms/#terms'>Terms of Use</HashLink></p>
                    <p><HashLink to='/sla/#slaa'>SLA</HashLink></p>
                    <p><HashLink to='/privacy/#privacy'>Privacy Policy</HashLink></p>
                    <p><HashLink to='/data_security/#data_security'>Data Security</HashLink></p>
                </div>
            </div>
        </div>
    )
}

export default Footer
