import React from 'react'
import CareerBanner from '../components/banners/CareerBanner'
import Careers from '../components/DetailComponents/Careers'

const Career = () => {
    return (
        <>
            <CareerBanner />
            <Careers />
        </>
    )
}

export default Career