import React from 'react'

const Content = () => {
    const mystyle = {
        color: "red",
        margin: 0,
        padding: 0,
        letterSpacing: 0
    };
    return (
        <div className="container">
            <div className="about-us">
                <div className="content">
                    <h3>The Award-winning, Unique Process to Implement any Management Framework: <h2>TRICEA</h2></h3>
                    <p style={{ textAlign: "left" }}>
                        <i>Becoming Data-Driven Decision-Makers (3DM)</i>
                        <br />
                        When we started to consult for organizations on their process issues and pain points, it was like fixing patches
                        here and there where there were challenges but the leakage would eventually pop up elsewhere in the system.
                        Most OKR Implementations happen this way – without understanding the &ldquo;WHY&rdquo; of the implementation –
                        no wonder we have so many issues and blind spots that remain unaddressed making the whole project bloated with
                        problems and collecting huge organizational debt. To ensure that OKR momentum can be sustained DN LLP
                        designed a comprehensive and integrative approach to OKR Implementation.  This has helped make all the
                        projects we have done a sustained success.  This model is acronymized TRICEA and covers the following steps
                        while designing the approach which is always unique to each organization.
                    </p>
                    <p style={{ textAlign: "left" }}>
                        <b><span style={mystyle}>T</span>racked</b> – No disciplined tracking, no success.  This step ensures you have
                        the right, proven templates, tools, and disciplines to track and measure your OKRs at defined intervals,
                        which can be different for different teams.  We have already covered how important execution discipline is.
                    </p>
                    <p style={{ textAlign: "left" }}>
                        <b><span style={mystyle}>R</span>eviewed and <span style={mystyle}>R</span>ectified</b> – Make sure you follow
                        the 3DM process and make regular course corrections instead of a flat/mechanical run of OKRs through the entire
                        quarter.  During this process, make sure you make adjustments and enhancements – both - by taking constant
                        feedback.
                    </p>
                    <p style={{ textAlign: "left" }}>
                        <b><span style={mystyle}>C</span>elebrated</b> – Design a complementing rewards and recognition system in
                        alignment with your culture and performance measurement system.
                    </p>
                    <p style={{ textAlign: "left" }}>
                        <b><span style={mystyle}>E</span>ngaging</b> – We have covered employee engagement principles in deail already.
                        When you design your OKR Approach, use key people from all levels to create the governing framework and support
                        system needed.
                    </p>
                    <p style={{ textAlign: "left" }}>
                        <b><span style={mystyle}>A</span>ligned</b> – Create and communicate visually how you are ensuring alignment
                        across levels in the organizations.  What gets visually communicated, get your team&apos;s commitment.  Also,
                        create a strategic communication plan to continually reinforce th alignment, through the entire duration of
                        the project to demonstrate how dynamic and agile the alignment is.  This reflects the real power and flavor
                        of OKRs.
                        <br />
                        When you design your OKR Program approach using the TRICEA model, you can fix lot of the narrative and
                        execution issues from day 1.  It will also help you ensure that there is no lack of collaboration amongst
                        teams and also give due recognition to Individual OKRs and contributions.
                        <br />
                        TRICEA will compel you to make sure that your OKR Program is not a purely HR-driven initiative without executive involvement.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Content