import React from 'react'

const PerformanceManagement = () => {
    return (
        <div className="container">
            <div className="about-us">
                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div className="content">
                            <h2>Assess the Organizational Performance</h2>
                            {/* <span>Assess the organizational performance</span> */}
                            {/* <h2>Measurement is the Key Success Factor to Performance</h2> */}
                            <p><i>Measurement is the Key Success Factor in Performance</i></p>
                            <p>
                                But even more fundamental to measurement is designing measurable goals.
                                Use our award-winning methodology to get your growth goals right.
                            </p>
                            <p>Good organizational performance needs:</p>
                            <ul>
                                <li>Accurate and reliable measurement</li>
                                <li>Track and analyze performance across levels and generate reports.</li>
                                <li>Track key contributors against key performance indicators (KPIs).</li>
                                <li>Get real-time visibility into the progress and outcomes of your OKRs.</li>
                                <li>
                                    Use the data to identify areas for
                                    <ul>
                                        <li>Organizational Improvement.</li>
                                        <li>Making informed decisions.</li>
                                        <li>Redesigning and rehashing your goals.</li>
                                        <li>Making market corrections in alignment with your growth goals.</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div className="collage">
                            <div className="grey-bg">
                                <img src="/success.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PerformanceManagement