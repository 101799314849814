import { faArrowUpFromBracket, faBarsProgress, faBullseye, faChartLine, faDrawPolygon, faFootballBall, faStar, faStarOfLife } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { ProgressBar } from 'react-bootstrap'

const ProductOverview = () => {
    return (
        <div className="container" id='product_overview'>
            <div className="about-us">
                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div className="collage">
                            <div className="grey-bg">
                                <img src="/goal.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div className="content">
                            <span>The Key to Achieving Growth and Success.</span>
                            <h2>Make your Goals Work Harder!</h2>
                            <p>The Secret Sauce – Understanding the CORRECT syntax for OKRs.</p>
                            <p>Our tool and our coaching will help you understand the language of OKRs and pick the vocabulary at
                                a fast pace.</p>
                            <p>Our software – which is based on the award-winning TRICEA model - helps you break down OKRs the right
                                way and consolidate the teams&apos; and individuals&apos; contributions towards achieving
                                collective OKRs.  How, you ask?</p>
                            <ul>
                                <li>CALYBER® gives you increased visibility into the progress of the objectives
                                    and key results with discipline pre-defined check-in schedules.
                                </li>
                                <li>
                                    CALYBER® enables organizations to focus on their most important objectives,
                                    and prioritize key results and initiatives through Eisenhower&apos;s principle.
                                </li>
                                <li>
                                    Whether you're a small startup or a large multinational corporation, our software
                                    can help you achieve your growth objectives with ease.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <br />
                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div className="content">
                            <h2>EXECUTE YOUR STRATEGIC IMPERATIVES. PRECISELY. WITH DUE RECOGNITION.</h2>
                            <p>
                                &emsp;With our unique tool, CALYBER®, you can ensure that all organizational contributors
                                are aligned, recognized, held accountable, and focused on achieving the common objectives,
                                while still allowing for flexibility in how those objectives are achieved.
                            </p>
                            <p>If you are looking to </p>
                            <ul>
                                <li>Establish a culture of accountability. </li>
                                <li>Bring in measured transparency across the organization.</li>
                                <li>Have collaborative goals areas and yet, have sovereign goals as well.</li>
                                <li>Achieve better organizational results over time.</li>
                            </ul>
                            <p>
                                Then, we invite you to use CALYBER® and explore how it can help your organization and teams achieve their full potential.
                            </p>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div className="collage">
                            <div className="grey-bg">
                                <img src="/strategy_precise.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <div className="why-krama">
                    <div className="cards row">
                        <div className="card col-xl-2 col-lg-2 col-md-6 col-sm-12">
                            <FontAwesomeIcon icon={faDrawPolygon} color='orange' size='2xl' />
                            <h5>Design your goals</h5>
                            <p>Our integrated coaching service will help you leverage design-thinking principles to get your goals right. At Ease!</p>
                        </div>
                        <div className="card col-xl-2 col-lg-2 col-md-6 col-sm-12">
                            <FontAwesomeIcon icon={faBarsProgress} color='orange' size='2xl' />
                            <h5>Co-Own and Collaborate</h5>
                            <p>Understand the technique of co-owning and collaborating with your colleagues to create shared success.</p>
                        </div>
                        <div className="card col-xl-2 col-lg-2 col-md-6 col-sm-12">
                            <FontAwesomeIcon icon={faChartLine} color='orange' size='2xl' />
                            <h5>Measure and Match</h5>
                            <p>Compare your performances by slicing and dicing them across time horizons to get the business outcome you are looking for.</p>
                        </div>
                        <div className="card col-xl-2 col-lg-2 col-md-6 col-sm-12">
                            <FontAwesomeIcon icon={faStar} color='orange' size='2xl' />
                            <h5>Review and Grow</h5>
                            <p>Use the rich reports features to view organization-wide performance and individual performances to chart your growth story better.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ProductOverview