import React from 'react'
import TermsContent from '../components/policy_pages/TermsContent'

const Terms = () => {
    return (
        <>
            <TermsContent />
        </>
    )
}

export default Terms