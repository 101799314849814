import axios from 'axios';
import { Buffer } from 'buffer';

const baseDomain = 'https://calyber.io:3022/krama_api_v1';

const username = 'abckrama'
const password = 'abckrama'

// const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64')
const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64')

const customeHeaders = {
    'Authorization': `Basic ${token}`
};

const baseURL = `${baseDomain}`;

export const axiosInstance = axios.create({
    baseURL,
    headers: customeHeaders,
});