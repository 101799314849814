import React from 'react'

const ProductCards1 = () => {
    return (
        <div className="container">
            <div className="product-cards-1 row">
                <div className="cards col-xl-3 col-lg-3 col-md-4 col-sm-6">
                    <div className="details one">
                        <div className="number">
                            <div className="content">1</div>
                        </div>
                        <h6>Create & Prioritize Key Results and Initiatives</h6>
                    </div>
                </div>
                <div className="cards">
                    <div className="details two">
                        <div className="number">
                            <div className="content">2</div>
                        </div>
                        <h6>Colloborate with teams and track them</h6>
                    </div>
                </div>
                <div className="cards">
                    <div className="details three">
                        <div className="number">
                            <div className="content">3</div>
                        </div>
                        <h6>Allocate appropriate weightages</h6>
                    </div>
                </div>
                <div className="cards">
                    <div className="details four">
                        <div className="number">
                            <div className="content">4</div>
                        </div>
                        <h6>Map your initiatives with key results</h6>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductCards1