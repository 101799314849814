import React from 'react'
import ContactBanner from '../components/banners/ContactBanner'
import ContactTouch from '../components/contact_page/ContactTouch'
import FAQ from '../components/contact_page/FAQ'
import SupportForm from '../components/contact_page/SupportForm'
import ContactForm from '../components/home_page/ContactForm'

const Contact = () => {
    return (
        <>
            <ContactBanner />
            {/* <SupportForm />
            <FAQ /> */}
            {/* <ContactTouch /> */}
            <ContactForm />
        </>
    )
}

export default Contact