import React from 'react'

const ResourcesSecondBanner = () => {
    return (
        <div className="container" id='rsr_buy'>
            <div className="about-us">
                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div className="collage">
                            <img src="/resources-banner-2.png" alt="" />
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div className="content">
                            <span>Download/buy</span>
                            <h2>Our OKR resources</h2>
                            <p>Download free ebooks from our library and learn more about the Objectives and Key Results (OKR) goal-setting framework and bridge the gap between strategy and execution.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResourcesSecondBanner