import React, { useEffect, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import PhoneInput from 'react-phone-number-input'
import { toast, ToastContainer } from 'react-toastify'
import { axiosInstance } from '../../axios/instance'

const SupportForm = () => {
    const [firstName, setFirstName] = useState()
    const [firstNameErr, setFirstNameErr] = useState()
    const [lastName, setLastName] = useState()
    const [lastNameErr, setLastNameErr] = useState()
    const [phone, setPhone] = useState()
    const [phoneErr, setPhoneErr] = useState()
    const [email, setEmail] = useState()
    const [emailErr, setEmailErr] = useState()
    const [findUs, setFindUs] = useState()
    const [findUsErr, setFindUsErr] = useState()
    const [issues, setIssues] = useState()
    const [issuesErr, setIssuesErr] = useState()

    const [verify, setVerify] = useState(false)

    const notify = (notification) => toast(notification, {
        autoClose: 1000
    });

    const notifyErr = (notification) => toast.error(notification, {
        autoClose: 1000
    });

    const notifyWarning = (notification) => toast.warning(notification, {
        autoClose: 1000
    });

    useEffect(() => {
        if (firstName)
            setFirstNameErr()
        if (lastName)
            setLastNameErr()
        if (phone)
            setPhoneErr()
        if (email)
            setEmailErr()
        if (issues)
            setIssuesErr()
        if (findUs)
            setFindUsErr()
    }, [firstName, lastName, phone, email, findUs, issues])

    const requestSupport = () => {
        if (verify) {
            if (firstName && lastName && phone && email && issues && findUs) {
                axiosInstance.post('/enquiry/request_support', {
                    firstName,
                    lastName,
                    phone,
                    email,
                    findUs,
                    issues
                }).then(response => {
                    if (!response.data.error) {
                        notify('Your query submitted successfully, will get back to you soon.')
                        setFirstName('')
                        setLastName('')
                        setPhone()
                        setEmail('')
                        setFindUs('')
                        setIssues('')
                    } else {
                        notifyErr('Unexpected error occured')
                    }
                }).catch(error => {
                    console.log(error);
                })
            } else {
                if (!firstName)
                    setFirstNameErr('First name required')
                if (!lastName)
                    setLastNameErr('Last name required')
                if (!phone)
                    setPhoneErr('Phone number required')
                if (!email)
                    setEmailErr('Email required')
                if (!issues)
                    setIssuesErr('Issues required')
                if (!findUs)
                    setFindUsErr('Input required')
            }
        } else {
            notifyWarning('Verify that you are human.')
        }
    }
    return (
        <div className="container" id='support'>
            <ToastContainer />
            <p style={{ textAlign: 'justify' }}>
                &ensp;Whether you have a question about our pricing plans, features, or need technical assistance, we are here to help.
                You can reach us through our contact form, email, or phone, and we will respond promptly to your inquiry. We value
                your feedback and suggestions, as they help us improve our product and services to better serve our customers. So,
                please don&apos;t hesitate to reach out to us and let us know how we can assist you. We forward to hearing from you soon!
            </p>
            <div className="support-form">
                <div className="yellow-bg"></div>
                <div className="form">
                    <h2>Support</h2>
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 column">
                            <label htmlFor="">first name <span>*</span></label>
                            <input type="text" placeholder='Enter your first name' value={firstName} onChange={e => setFirstName(e.target.value)} />
                            <label htmlFor=""><span>{firstNameErr && firstNameErr}</span></label>
                            <label htmlFor="">email address <span>*</span></label>
                            <input type="text" placeholder='Enter your email address' value={email} onChange={e => setEmail(e.target.value)} />
                            <label htmlFor=""><span>{emailErr && emailErr}</span></label>
                            <label htmlFor="">phone number <span>*</span></label>
                            <PhoneInput
                                placeholder="Enter phone number"
                                value={phone}
                                onChange={setPhone}
                                style={{ width: '90%' }}
                            />
                            <label htmlFor=""><span>{phoneErr && phoneErr}</span></label>
                            <label htmlFor="">How did you find us? <span>*</span></label>
                            <input type="text" placeholder='How did you find us?' value={findUs} onChange={e => setFindUs(e.target.value)} />
                            <label htmlFor=""><span>{findUsErr && findUsErr}</span></label>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 column">
                            <label htmlFor="">last name </label>
                            <input type="text" placeholder='Enter your last name' value={lastName} onChange={e => setLastName(e.target.value)} />
                            <label htmlFor=""><span>{lastNameErr && lastNameErr}</span></label>
                            <label htmlFor="">issues &ensp;</label>
                            <textarea type="text" placeholder='Enter your issue' value={issues} onChange={e => setIssues(e.target.value)} />
                            <label htmlFor=""><span>{issuesErr && issuesErr}</span></label>
                            <ReCAPTCHA
                                sitekey='6LeR92QmAAAAAGbgjrwXSeQCjMz0O8jqQoYsZ7OL'
                                onChange={() => setVerify(true)}
                            />
                            <br/>
                            <div className="button-full" onClick={requestSupport}>
                                Submit
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SupportForm