import React, { useEffect } from 'react'
import { redirect } from 'react-router-dom'

const Success = () => {

    useEffect(() => {
        setInterval(() => {
            if (typeof window !== 'undefined')
                window.location.href = '/'
        }, [3000])
    }, [])
    return (
        <div className="container">
            <div className="latest-updates success" >
                <h2>Payment Successful</h2>
                <p style={{ textAlign: 'justify' }}>
                    You will be automatically redirected to the home page or <a href='/'>Click here</a> to navigate..!
                </p>
                <p style={{ textAlign: 'justify' }}>
                    Thanks for using Calyber.
                </p>
            </div>
        </div >
    )
}

export default Success