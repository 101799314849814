import React from 'react'

const Cs = () => {
    return (
        <div className="container">
            <div className="cs">
                <h2>Coming Sooon ..!</h2>
            </div>
        </div>
    )
}

export default Cs