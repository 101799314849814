import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import RegisterForm from '../register_form/RegisterForm'
import { HashLink } from 'react-router-hash-link'

const PricingPlans = ({ data }) => {

    const [selectedItem, setSelectedItem] = useState()
    const [show, setShow] = useState(false)

    const handleSelection = (item) => {
        setSelectedItem(item)
        setShow(true)
    }

    return (
        <div className="container" id='price_plans'>
            <div className="pricing-plans">
                <span>Pricing plans</span>
                <h2>Let&apos;s get you Started with the Right Plan!</h2>
                <p style={{ textAlign: 'justify' }}>
                    &emsp;At Calyber, we offer a range of pricing plans to suit businesses of all sizes. Whether you&apos;re a
                    small startup or a large enterprise, we have the right plan for you. Our pricing plans are
                    designed to provide maximum value for your investment, with features that help you achieve
                    your business goals.In addition to our standard plans, we also offer additional consulting and coaching services
                    to help you get the most out of our platform. Our expert consultants and coaches can help you
                    develop and implement a successful OKR strategy that aligns with your business objectives.
                    To get started, simply choose the plan that&apos;s right for your business. With our transparent and affordable
                    pricing plans, you can rest assured that you&apos;re getting the best value for your investment. So why wait?
                    Let&apos;s get started with the right plan and take your organization to the next level with Calyber.
                </p>
                <span>Flexible and Modular Pricing for Organiations of all sizes</span>
                <p>
                    Calyber ensures its flexibility and affordability for businesses of all sizes and types when it comes
                    to pricing. By offering modular pricing, customers can easily scale up or down as their needs change,
                    making it a cost-effective solution for businesses that are just starting out or have varying needs
                    throughout the year. Overall, our approach ensures that customers receive the best value for their
                    membership with Calyber and our services.
                </p>
                {/* <div className="cards row">
                    {data && data.map(item => (
                        <div className="card col-xl-2 col-lg-2 col-md-5 col-sm-12" key={item.id} style={{ backgroundColor: item.color_code }}>
                            <div className="image-section">
                                <img src={item.image_path} alt="" />
                            </div>
                            <div className="content">
                                <h5>{item.name}</h5>
                                <p>{item.short_description}</p>
                                <h3>₹ {item.amount}/month</h3>
                                <div className="row">
                                    <div className="icon">
                                        <FontAwesomeIcon icon={faCheck} color='white' />
                                    </div>
                                    <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</p>
                                </div>
                                <div className="row">
                                    <div className="icon">
                                        <FontAwesomeIcon icon={faCheck} color='white' />
                                    </div>
                                    <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</p>
                                </div>
                                <div className="row">
                                    <div className="icon">
                                        <FontAwesomeIcon icon={faCheck} color='white' />
                                    </div>
                                    <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</p>
                                </div>
                                <div className="row">
                                    <div className="button-full">Request a Demo</div>
                                    <div className="button-empty" onClick={() => handleSelection(item)}>Get Started</div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div> */}
                <div className="row">
                    <p>Write to us on <a href="mailto:contact@calyber.io" target='_blank' rel="noreferrer">contact@calyber.io</a> Or Request for a Demo below</p>
                </div>
            </div>
            <Modal className='modal_stripe' show={show} centered onHide={() => setShow(false)}>
                <div className="p-5">
                    <RegisterForm item={selectedItem && selectedItem} />
                </div>
            </Modal>
        </div>
    )
}

export default PricingPlans